import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import PostForm from '../postform'
import { useYouTube } from '../../context/youtube/YouTubeProvider'
import moment from 'moment'

import { Card } from '../card'
import { Block } from '../block'
import YouTube, { Options } from 'react-youtube'

type Props = RouteComponentProps<{ provider: string; videoId: string }>

const Post: React.FC<Props> = ({
  match: {
    params: { videoId, provider }
  }
}) => {
  const {
    youTubeVideo,
    getVideos,
    setCurrentVideo,
    isFetching,
    error
  } = useYouTube()
  const [fetchingVideoDetails, setFetchingVideoDetails] = useState<boolean>(
    false
  )
  const [errorDetails, setErrorDetails] = useState<Error | null>(null)
  console.log('TOP OF POST!', videoId, youTubeVideo)
  useEffect(() => {
    async function getData() {
      try {
        let vids = await getVideos([videoId])
        if (vids?.length) {
          console.log(
            'set details',
            vids[0],
            !fetchingVideoDetails,
            !errorDetails,
            youTubeVideo
              ? youTubeVideo.id !== videoId ||
                  !youTubeVideo.hasOwnProperty('contentDetails')
              : true
          )
          setCurrentVideo(vids[0])
        } else {
          setErrorDetails(new Error('no results from api :('))
        }
        setFetchingVideoDetails(false)
      } catch (e) {
        console.error(e)
        setErrorDetails(e)
        setFetchingVideoDetails(false)
      }
    }
    if (
      !fetchingVideoDetails &&
      !errorDetails &&
      (youTubeVideo
        ? youTubeVideo.id !== videoId ||
          !youTubeVideo.hasOwnProperty('contentDetails')
        : true)
    ) {
      setFetchingVideoDetails(true)
      getData()
    }
  }, [
    getVideos,
    setCurrentVideo,
    videoId,
    youTubeVideo,
    fetchingVideoDetails,
    errorDetails
  ])

  if (error || errorDetails) {
    return (
      <ScPost>
        <ScError>Oops, something went wrong</ScError>
      </ScPost>
    )
  }

  if (isFetching || fetchingVideoDetails) {
    return (
      <ScPost>
        <Block color="black" />
      </ScPost>
    )
  }

  if (!youTubeVideo || !youTubeVideo.contentDetails) {
    console.log('no video or video details, but not loading')
    return null
  }

  console.log('convert duration', youTubeVideo.contentDetails.duration)
  let duration = String(
    moment.duration(youTubeVideo.contentDetails.duration).asMilliseconds() /
      1000
  )

  const opts: Options = {
    height: '390',
    width: '100%',
    playerVars: {
      autoplay: 0,
      start: 0,
      rel: 0,
      fs: 0
    }
  }

  // ToDo - Route to channel path instead of setting here
  return (
    <ScPost>
      <Card>
        <YouTube className="player" opts={opts} videoId={videoId} />
        <br />
        <PostForm
          mode="video"
          duration={duration}
          value={youTubeVideo.id}
        ></PostForm>
      </Card>
    </ScPost>
  )
}

export default Post

const ScError = styled.div`
  color: red;
`

const ScPost = styled.div`
  width: 100%;
  margin: 1rem auto;
  max-width: 720px;
`

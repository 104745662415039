import React, { useState, useCallback, useEffect } from 'react'
import YouTube, { Options } from 'react-youtube'
import styled from 'styled-components'
import { usePlayer } from '../../context/player/PlayerProvider'
import { useBmap } from '../../context/bmap/BmapProvider'
import isEqual from 'lodash/isEqual'

interface Props {
  channel: string
  fullscreen?: boolean
}

const Player: React.FC<Props> = ({ channel: chan, fullscreen }) => {
  const { channel, pop } = useBmap()
  const { position, video, tx, isLive, setLive, playNextOrRandom } = usePlayer()
  const [player, setPlayer] = useState<any>()
  const [startPos, setStartPos] = useState<number | null>(null)
  const [playerOptions, setPlayerOptions] = useState<Options>({
    height: '590',
    width: '640',
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0
    }
  })

  useEffect(() => {
    const c = channel(chan)
    let addSeconds =
      tx && c.playlist.length
        ? Math.floor((new Date().getTime() - tx.timestamp) / 1000)
        : 0
    console.log('set start position', tx, position, addSeconds)
    // ToDo - This can add more seconds than available in the video.
    // It should really apply the remainder to the next video and so on until its at the right position on the right item
    setStartPos(position + addSeconds)
  }, [channel, chan, tx, position])

  useEffect(() => {
    // Make sure it has been set by previous effect
    if (startPos === null) {
      return
    }
    let txStart = parseInt(tx && tx.MAP && tx.MAP.start ? tx.MAP.start : '0')
    let txDuration = parseInt(
      tx && tx.MAP && tx.MAP.duration ? tx.MAP.duration : '0'
    )
    // https://developers.google.com/youtube/player_parameters

    const opts: Options = {
      height: '590',
      width: '640',
      playerVars: {
        autoplay: 1,
        start: txStart > startPos ? txStart : startPos,
        end: Math.min(txStart + txDuration, video ? video.duration : 0),
        controls: 1, // isLive ? 0 : 1,
        rel: 0
      }
    }
    if (!isEqual(opts, playerOptions)) {
      setPlayerOptions(opts)
    }
  }, [startPos, tx, video, playerOptions])

  useEffect(() => {
    let state = channel(chan)
    if (state.playlist.length > 0) {
      if (!isLive) {
        setLive(true)
      }
    } else {
      if (isLive) {
        setLive(false)
      }
    }
  }, [chan, channel, isLive, setLive])

  const onReady = useCallback(
    (e: any) => {
      if (!player) {
        setPlayer(e.target)
      }
    },
    [player]
  )

  const onPause = useCallback(() => {
    setLive(false)
  }, [setLive])

  const onEnd = useCallback(
    (e) => {
      console.log('ended!', e)

      pop(chan)

      // Play next in playlist, or random from history
      playNextOrRandom(chan)
    },
    [pop, playNextOrRandom, chan]
  )

  const goLive = useCallback(() => {
    window.location.reload()
  }, [])

  if (!tx || !video) {
    console.log('no tx or video :(', tx, video)
    return null
  }

  return (
    <ScPlayer>
      <ScVideo>
        <YouTube
          className={fullscreen ? 'player-fullscreen' : 'player'}
          opts={playerOptions}
          videoId={video?.videoID}
          onPause={onPause}
          onReady={onReady}
          onEnd={onEnd}
        />
        {!channel(chan).playlist.length ? null : isLive ? (
          <ScLiveIndicator>Live</ScLiveIndicator>
        ) : (
          <ScLiveIndicator
            onClick={() => {
              goLive()
            }}
          >
            GoLive
          </ScLiveIndicator>
        )}
      </ScVideo>
    </ScPlayer>
  )
}

export default Player

const ScLiveIndicator = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 0.25rem;
  z-index: 10;
  color: #fff;
  background: #000;
  border-radius: 0.25rem;
`

const ScVideo = styled.div<{ fullscreen?: boolean }>`
  position: relative;
  height: ${(props) => (props.fullscreen ? '100hv' : '100%')};
`

const ScPlayer = styled.div`
  grid-row: 1;
  grid-column: 1;
  grid-gap: 0.5rem;
`

import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { useBmap } from '../../context/bmap/BmapProvider'
import { Block } from '../block'

type Props = RouteComponentProps<any>

const Login: React.FC<Props> = (props) => {
  const { isFetching, error } = useBmap()

  if (error) {
    return (
      <ScLogin>
        <ScError>Oops, something went wrong</ScError>
      </ScLogin>
    )
  }

  if (isFetching) {
    return (
      <ScLogin>
        <Block color="blue" />
      </ScLogin>
    )
  }

  return (
    <ScLogin>
      <ScTitle>Login</ScTitle>
      Username: <input type="text" name="username" />
      <br />
      Password: <input type="text" name="password" />
    </ScLogin>
  )
}

export default Login

const ScTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
`

const ScError = styled.div`
  color: red;
`

const ScLogin = styled.div`
  width: 100%;
  margin: 0.5rem auto;
`

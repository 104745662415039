import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import Player from '../player'
import { Card } from '../card'
import QRCode from 'qrcode.react'
type Props = RouteComponentProps<{ channel: string }>

const Embed: React.FC<Props> = ({
  match: {
    params: { channel }
  }
}) => {
  return (
    <ScEmbed>
      <Player channel={channel} fullscreen={true}></Player>
      <Card
        style={{
          textAlign: 'center',
          position: 'absolute',
          bottom: '.5rem',
          right: '0.5rem',
          pointerEvents: 'none'
        }}
      >
        <h5>Scan with RelayX</h5>
        <QRCode
          value={`https://minerva.live/remote/${channel}`}
          bgColor={'#eee'}
          fgColor={'#111'}
          includeMargin={true}
          size={200}
          style={{ margin: 'auto' }}
          level={'M'}
        />
        <h4>Mobile Remote Control</h4>
      </Card>
    </ScEmbed>
  )
}

export default Embed

const ScEmbed = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  margin: auto;
  height: 100vh;
`

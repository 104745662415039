import { Video, YouTubeVideo } from 'minerva'
import React, { useCallback, useContext, useMemo, useState } from 'react'

const ROOT_URL = 'https://www.googleapis.com/youtube/v3'
const API_KEY = 'AIzaSyBkMbwfuuvQOJtVovnIX3jKgWv-M-8i8vQ'

type ContextValue = {
  getVideos: (videoIds: string[]) => Promise<YouTubeVideo[]>
  search: (tx: string) => Promise<void>
  isFetching: boolean
  setCurrentVideo: (v: YouTubeVideo) => void
  youTubeVideo: YouTubeVideo | null
  youTubeVideos?: YouTubeVideo[] | null
  error: Error | null
  errorSearch: Error | null
  setQuery: (query: string) => void
  query: string
}

interface Props {}

const YouTubeContext = React.createContext<ContextValue | undefined>(undefined)

const YouTubeProvider: React.FC<Props> = (props) => {
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [errorSearch, setErrorSearch] = useState<Error | null>(null)
  const [youTubeVideo, setYouTubeVideo] = useState<YouTubeVideo | null>(null)
  const [youTubeVideos, setYouTubeVideos] = useState<YouTubeVideo[] | null>(
    null
  )
  const [query, setQ] = useState<string>('')

  const search = useCallback(
    async (term: string) => {
      if (errorSearch) {
        console.log('search error on top', errorSearch)
        throw errorSearch
      }

      if (isFetching || errorSearch) {
        let e = new Error('query while fetching or while errored')
        setErrorSearch(e)
        throw e
      }

      if (!term.length) {
        let e = new Error("can't search for empty serch term")
        setErrorSearch(e)
        throw e
      }

      let params: any = {
        part: 'snippet',
        key: API_KEY,
        q: term,
        type: 'video',
        maxResults: 10
      }

      let url = new URL(ROOT_URL + '/search')
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      )

      setIsFetching(true)
      setYouTubeVideos([])
      try {
        let response = await fetch(url.toString())
        if (response.status !== 200) {
          let e = new Error('Bad status code ' + response)
          setErrorSearch(e)
          throw e
        }
        let j = await response.json()

        j.items = j.items.map((v: any) => {
          v.id = v.id.videoId
          return v
        }) as YouTubeVideo

        setYouTubeVideos(j.items)

        setIsFetching(false)
      } catch (e) {
        setIsFetching(false)
        setErrorSearch(e)
        return e
      }
    },
    [isFetching, errorSearch]
  )

  const setCurrentVideo = useCallback((v: YouTubeVideo) => {
    setYouTubeVideo(v)
  }, [])

  const setQuery = useCallback((query: string) => {
    setQ(query)
  }, [])

  const getVideos = useCallback(
    async (videoIds: string[]) => {
      if (isFetching || error) {
        return
      }
      // remove duplicate ids
      let ids = new Set()
      for (let i = 0; i < videoIds.length; i++) {
        if (!ids.has(videoIds[i])) {
          ids.add(videoIds[i])
        }
      }
      videoIds = Array.from(ids) as string[]
      let params: any = {
        part: 'snippet,contentDetails',
        key: API_KEY,
        id: videoIds.join(',')
      }

      let url = new URL(ROOT_URL + '/videos')
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      )

      setIsFetching(true)
      try {
        let response = await fetch(url.toString())
        console.log('got videos', response)
        if (response.status !== 200) {
          let e = new Error('Bad status code')
          setIsFetching(false)
          setError(e)
          throw e
        }

        let j = await response.json()
        setIsFetching(false)
        return j.items as Video[]
      } catch (e) {
        setIsFetching(false)
        setError(e)
        return e
      }
    },
    [isFetching, error]
  )

  const value = useMemo(
    () => ({
      getVideos,
      search,
      isFetching,
      error,
      errorSearch,
      setCurrentVideo,
      youTubeVideo,
      setQuery,
      query,
      youTubeVideos,
      setYouTubeVideos
    }),
    [
      error,
      errorSearch,
      isFetching,
      getVideos,
      search,
      setCurrentVideo,
      youTubeVideo,
      youTubeVideos,
      setYouTubeVideos,
      setQuery,
      query
    ]
  )

  return <YouTubeContext.Provider value={value} {...props} />
}

const useYouTube = (): ContextValue => {
  const context = useContext(YouTubeContext)
  if (context === undefined) {
    throw new Error('useYouTube must be used within an YouTubeProvider')
  }
  return context
}

export { YouTubeProvider, useYouTube }

import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import {
  ROUTES__AUTHENTICATE,
  ROUTES__PROFILE,
  ROUTES__LANDING,
  ROUTES__LOGIN,
  ROUTES__PROTOCOL,
  ROUTES__WATCH,
  ROUTES__CHANNEL,
  ROUTES__CHANNELS,
  ROUTES__EMBED,
  ROUTES__SEARCH,
  ROUTES__REMOTE,
  ROUTES__HELP,
  ROUTES__POST
} from '../../constants'
import Watch from '../watch'
import Channel from '../channel'
import Profile from '../profile'
import Protocol from '../protocol'
import Landing from '../landing'
import Login from '../login'
import Search from '../search'
import Channels from '../channels'
import Remote from '../remote'
import Embed from '../embed'
import Post from '../post'
import Help from '../help'
import GoBack from './GoBack'
import PrivateRoute from './PrivateRoute'

interface Props {}

const Routes: React.FC<Props> = () => {
  return (
    <Switch>
      <Route path={`${ROUTES__HELP}`} component={Help} />
      <Route path={`${ROUTES__POST}/:provider/:videoId`} component={Post} />
      <Route path={`${ROUTES__SEARCH}/:query`} component={Search} />
      <Route path={`${ROUTES__WATCH}/:videoId`} component={Watch} />
      <Route path={`${ROUTES__LOGIN}`} component={Login} />
      <Route path={`${ROUTES__EMBED}/:channel`} component={Embed} />
      <Route path={`${ROUTES__CHANNEL}/:channel`} component={Channel} />
      <Route path={`${ROUTES__REMOTE}/:channel`} component={Remote} />
      <Route path={`${ROUTES__PROFILE}/:paymail`} component={Profile} />
      <Route path={`${ROUTES__PROTOCOL}`} component={Protocol} />
      <Route path={ROUTES__CHANNELS} component={Channels} />

      <PrivateRoute path={ROUTES__AUTHENTICATE} component={GoBack} />
      <Route path={`${ROUTES__LANDING}`} component={Landing} />

      <Redirect to={ROUTES__LANDING} />
    </Switch>
  )
}

export default Routes

export const ROUTES__WATCH = '/watch'
export const ROUTES__CHANNEL = '/channel'
export const ROUTES__PROFILE = '/profile'
export const ROUTES__PROTOCOL = '/protocol'
export const ROUTES__PROFILE_EDIT = '/edit'
export const ROUTES__LANDING = '/'
export const ROUTES__LOGIN = '/login'
export const ROUTES__AUTHENTICATE = '/authenticate'
export const ROUTES__CHANNELS = '/channels'
export const ROUTES__HELP = '/help'
export const ROUTES__SEARCH = '/search'
export const ROUTES__POST = '/post'
export const ROUTES__REMOTE = '/remote'
export const ROUTES__EMBED = '/embed'

import { BmapTx } from 'bmapjs'
import { Video, YouTubeVideo } from 'minerva'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { useBmap } from '../../context/bmap/BmapProvider'
import { useUser } from '../../context/user/UserProvider'
import merge from '../../utils/bmap'

type ContextValue = {
  video: Video | null
  youtubeVideo: YouTubeVideo | null
  tx: BmapTx | null
  playVideo: (video: Video) => void
  isLive: boolean
  position: number
  setLive: (live: boolean) => void
  playNextOrRandom: (channel: string) => void
  currentChannel: string | null
  setCurrentChannel: (channel: string) => void
  pm: string | null
  isLiked: boolean
}

interface Props {}

const PlayerContext = React.createContext<ContextValue | undefined>(undefined)

const PlayerProvider: React.FC<Props> = (props) => {
  const [video, setVideo] = useState<Video | null>(null)
  const [tx, setTx] = useState<BmapTx | null>(null)
  const [position, setPosition] = useState<number>(-1)
  const {
    channelPlayed,
    channel,
    likedVideos,
    fetchLikedVideos,
    fetchLikesByTxId,
    getVideoByTxID
  } = useBmap()
  const { paymail } = useUser()
  const [isLive, setIsLive] = useState<boolean>(false)
  const [isLiked, setIsLiked] = useState<boolean>(false)
  const [likeChecked, setLikeChecked] = useState<string | null>(null)
  const [youtubeVideo, setYoutubeVideo] = useState<YouTubeVideo | null>(null)
  const [currentChannel, setCurrentChannel] = useState<string | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [pm, setPm] = useState<string | null>(null)
  const location = useLocation()

  const parsePm = useCallback((tx: BmapTx) => {
    // ToDo - Add support for this protocol to bmap or use a diff one
    if (!tx || !tx['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA']) {
      return
    }
    // This is a bit hacky
    let multipleSigs = Array.isArray(
      tx['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA'][0]
    )
    let sig = multipleSigs
      ? tx['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA'][0][4]
      : tx['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA'][4]

    setPm(sig.s)
  }, [])

  const playNextOrRandom = useCallback(() => {
    if (!currentChannel) {
      return
    }

    setPosition(0)
    // I think maybe this is stale?
    let c = channel(currentChannel)
    if (c.playlist.length === 0) {
      let p = channelPlayed(currentChannel)
      console.log('play random', currentChannel)
      let min = 0
      let max = p.playlist.length - 1
      min = Math.ceil(min)
      max = Math.floor(max || 0)
      let num = Math.floor(Math.random() * (max - min + 1) + min)
      console.log('play random: min', min, 'max', max, 'num', num)

      let next = p.playlist[num]
      if (next) {
        setCurrentChannel(p.channel)
        setVideo(p.playlist[num])
        setTx(null)
        setYoutubeVideo(null)
        // setTx(p.playlist[num])
      }
    } else {
      setCurrentChannel(c.channel)
      setVideo(c.playlist[0])
      setTx(null)
      setYoutubeVideo(null)
    }
  }, [channel, channelPlayed, currentChannel])

  // Set the current channel from location
  useEffect(() => {
    let [context, channelName] = location.pathname.split('/').slice(1)

    if (
      ['channel', 'embed', 'remote'].indexOf(context) !== -1 &&
      currentChannel !== channelName
    ) {
      setCurrentChannel(channelName)
    }
  }, [location, currentChannel])

  useEffect(() => {
    async function fetchData() {
      if (video) {
        console.log('fetching video from player provider')
        let videoData = await getVideoByTxID(video.txid)
        if (videoData) {
          let { videoTx, providerData: ytv } = videoData
          setYoutubeVideo(ytv)
          videoTx.timestamp = new Date().getTime()
          // merge the map stuff
          videoTx.MAP = Array.isArray(videoTx.MAP)
            ? merge(...videoTx.MAP)
            : videoTx.MAP

          setTx(videoTx)
          parsePm(videoTx)
          setIsFetching(false)
        }
      }
    }
    if (!isFetching && video && !youtubeVideo) {
      console.log('we got', video, isFetching)
      setIsFetching(true)
      fetchData()
    }
  }, [getVideoByTxID, video, isFetching, parsePm, youtubeVideo])

  useEffect(() => {
    if (!currentChannel) {
      return
    }
    let c = channel(currentChannel)
    if (c.playlist.length) {
      console.log('this channel has a playlist!', c.playlist[0])
      setVideo(c.playlist[0])
      setPosition(c.player.position)
    } else {
      console.log('this channel has no playlist')
      playNextOrRandom()
    }
  }, [parsePm, currentChannel, channelPlayed, channel, playNextOrRandom])

  useEffect(() => {
    async function fetchData(txid: string) {
      console.log('Getting liked for', tx?.tx.h, currentChannel)
      await fetchLikesByTxId(txid)
    }
    if (
      (currentChannel && likeChecked === null) ||
      (tx?.tx.h && likeChecked && tx.tx.h !== likeChecked)
    ) {
      if (!tx) {
        return
      }
      fetchData(tx.tx.h)
      setLikeChecked(tx.tx.h)
    }
  }, [likedVideos, likeChecked, tx, currentChannel, fetchLikesByTxId])

  useEffect(() => {
    if (likedVideos) {
      setIsLiked(
        likedVideos.some((l: Video) => {
          return l.txid === tx?.tx.h
        })
      )
    }
  }, [likedVideos, paymail, tx])

  const playVideo = useCallback(
    (v: Video) => {
      if (!currentChannel) {
        return
      }
      setPosition(0)
      let x = [channel(currentChannel)]
        .concat([channelPlayed(currentChannel)])
        .filter((s) => {
          return s.playlist.some((t) => {
            return t.txid === v.txid
          })
        })[0]
        .playlist.filter((t) => {
          return t.txid === v.txid
        })[0]

      setVideo(v)
      setTx(null)
      setYoutubeVideo(null)
    },
    [channel, channelPlayed, currentChannel]
  )

  const setLive = useCallback((l: boolean) => {
    setIsLive(l)
  }, [])

  const value = useMemo(
    () => ({
      video,
      youtubeVideo,
      tx,
      playNextOrRandom,
      playVideo,
      setLive,
      position,
      isLive,
      isLiked,
      currentChannel,
      setCurrentChannel,
      pm
    }),
    [
      video,
      youtubeVideo,
      tx,
      playNextOrRandom,
      playVideo,
      position,
      isLive,
      isLiked,
      setLive,
      currentChannel,
      setCurrentChannel,
      pm
    ]
  )

  return <PlayerContext.Provider value={value} {...props} />
}

const usePlayer = (): ContextValue => {
  const context = useContext(PlayerContext)
  if (context === undefined) {
    throw new Error('usePlayer must be used within an PlayerProvider')
  }

  return context
}

export { PlayerProvider, usePlayer }

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useBmap } from '../bmap/BmapProvider'

type ContextValue = {}

interface Props {}

const BitsocketContext = React.createContext<ContextValue | undefined>(
  undefined
)

const BitsocketProvider: React.FC<Props> = (props) => {
  const { handleSocket } = useBmap()
  const [socketInitialized, setSocketInitialized] = useState<boolean>(false)

  const connect = useCallback(() => {
    let query = {
      v: 3,
      q: {
        find: { 'MAP.app': 'minerva' }
      }
    }
    // Base64 encode bitquery
    const b64 = Buffer.from(JSON.stringify(query)).toString('base64')
    // Subscribe
    let surl = 'https://api.minerva.live/s/'
    return new EventSource(surl + b64)
  }, [])

  useEffect(() => {
    if (!socketInitialized) {
      console.log('no socket, connecting')
      let socket = connect()
      socket.onmessage = handleSocket
      setSocketInitialized(true)
    }
  }, [handleSocket, connect, socketInitialized])

  const value = useMemo(() => ({}), [])

  return <BitsocketContext.Provider value={value} {...props} />
}

const useBitsocket = (): ContextValue => {
  const context = useContext(BitsocketContext)
  if (context === undefined) {
    throw new Error('useBitsocket must be used within an BitsocketProvider')
  }
  return context
}

export { BitsocketProvider, useBitsocket }

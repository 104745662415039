import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import {
  ROUTES__LANDING,
  ROUTES__PROFILE,
  ROUTES__PROFILE_EDIT
} from '../../constants'
import { useHandcash } from '../../context/handcash/HandcashProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { useUser } from '../../context/user/UserProvider'
import { Authenticating } from '../authenticating'

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { user, error } = useUser()
  const { paymail, authenticated, authenticate } = useRelay()
  const { authToken, profile } = useHandcash()
  const [authenticating, setAuthenticating] = useState(true)

  const ensureAuthentication = useCallback(async () => {
    if (!authenticated && !authToken) {
      await authenticate()
    }
    setAuthenticating(false)
  }, [authToken, authenticated, authenticate])

  useEffect(() => {
    ensureAuthentication()
  }, [ensureAuthentication])

  if (authenticating) {
    return <Authenticating>Please pair your wallet to sign in.</Authenticating>
  }

  if (!authenticated) {
    // User declined to cooperate with relay authentication process
    return <Redirect to={ROUTES__LANDING} />
  }

  // Implicitly we know that the code below is authenticated with Relay and has the paymail available
  // Because the paymail is now available, the UserProvider now takes care of fetching the user from the database

  // If we know that the user doesn't exist in the database yet, we redirect him to the edit profile page
  if (error) {
    if (!paymail && !profile?.paymail) {
      // Just to be sure
      return null
    }
    return (
      <Redirect
        to={`${ROUTES__PROFILE}/${
          paymail || profile?.paymail
        }${ROUTES__PROFILE_EDIT}`}
      />
    )
  }

  return <Route {...props} />
}

export default PrivateRoute

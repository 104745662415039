import styled from 'styled-components'

export const Card = styled.div<{
  bgcolor?: string
  color?: string
  mobile?: boolean
}>`
  padding: ${(props) => (props.mobile ? '0' : '2rem')};
  background: ${(props) =>
    props.mobile
      ? 'transparent'
      : props.bgcolor
      ? props.bgcolor
      : 'rgba( 255, 255, 255, 0.08)'};
  color: ${(props) =>
    props.color ? props.color : 'rgba( 255, 255, 255, 88.8)'};
  border-radius: 0.5rem;
  box-sizing: border-box;
`

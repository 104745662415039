import React, { useCallback, useContext, useMemo, useState } from 'react'

type ContextValue = {
  relay: any
  relayotc: any
  paymail: string | null
  authenticate: () => Promise<void>
  authenticated: boolean
}

interface Props {}

const RelayContext = React.createContext<ContextValue | undefined>(undefined)

const RelayProvider: React.FC<Props> = (props) => {
  let pm = localStorage.getItem('paymail') as string
  const [paymail, setPaymail] = useState<string>(pm)

  const relay = (window as any).relayone
  const relayotc = (window as any).relayotc

  const authenticate = useCallback(async () => {
    try {
      const token: string = await relay.authBeta()
      if (token) {
        const payloadBase64 = token.split('.')[0] // Token structure: "payloadBase64.signature"
        const { paymail: returnedPaymail } = JSON.parse(atob(payloadBase64))
        localStorage.setItem('paymail', returnedPaymail)
        setPaymail(returnedPaymail)
      }
    } catch (e) {
      console.log(e)
    }
  }, [relay])

  const value = useMemo(
    () => ({
      relay,
      relayotc,
      paymail,
      authenticate,
      authenticated: !!paymail
    }),
    [relay, relayotc, paymail, authenticate]
  )

  return <RelayContext.Provider value={value} {...props} />
}

const useRelay = (): ContextValue => {
  const context = useContext(RelayContext)
  if (context === undefined) {
    throw new Error('useRelay must be used within an RelayProvider')
  }
  return context
}

export { RelayProvider, useRelay }

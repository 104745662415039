import { head } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
  ROUTES__AUTHENTICATE,
  ROUTES__CHANNELS,
  ROUTES__POST,
  ROUTES__PROFILE,
  ROUTES__SEARCH
} from '../../constants'
import { useHandcash } from '../../context/handcash/HandcashProvider'
import { usePlayer } from '../../context/player/PlayerProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { history } from '../../context/router/history'
import { useUser } from '../../context/user/UserProvider'
import { useYouTube } from '../../context/youtube/YouTubeProvider'
import getVideoId from '../../utils/video'
import SearchBar from '../find-video/search_bar'
interface Props {}

const Header: React.FC<Props> = () => {
  const { authenticated } = useRelay()
  const { user } = useUser()
  const { query } = useYouTube()
  const [channel, setChannel] = useState<string | null>(null)
  const { currentChannel } = usePlayer()
  const { profile } = useHandcash()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let parts = window.location.pathname.split('/')
    if (
      !channel &&
      parts.length === 3 &&
      (parts[1] === 'channel' || parts[1] === 'remote' || parts[1] === 'embed')
    ) {
      setChannel(parts.pop() || null)
    } else {
      setChannel(params.get('channel') || currentChannel)
    }
  }, [channel, currentChannel])

  // Check for urls
  const { id: videoId, service: provider } = getVideoId(query)
  if (videoId && provider) {
    console.log('redirecting to post page')
    history.push(`${ROUTES__POST}/${provider}/${videoId}`)
  }
  return (
    <>
      <ScHeader>
        <ScHeaderMax>
          <Link
            to={ROUTES__CHANNELS}
            style={{
              flex: 1,
              width: '14rem',
              textDecoration: 'none',
              color: '#fff'
            }}
          >
            <ScNav>
              <ScLogo>
                <MinervaLogo
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                >
                  <MinervaLogoPath
                    d="M28,2.47A.5.5,0,0,0,27.68,2a.51.51,0,0,0-.53.11,28.45,28.45,0,0,1-9.85,6.4.5.5,0,0,0-.26.27L16.56,10c-.07-.19-.15-.38-.23-.55a.5.5,0,0,0-.65-.23,17.52,17.52,0,0,0-5,3.12.5.5,0,0,0-.16.35,3.46,3.46,0,0,0,.21,1.26,1.49,1.49,0,0,1-.61-.3.51.51,0,0,0-.37-.11.5.5,0,0,0-.34.19,16.91,16.91,0,0,0-3,6.58.5.5,0,0,0,.82.48c2.46-2.24,4.16-3.54,5.66-4.68a59.24,59.24,0,0,0,7.51-6.65.51.51,0,0,1,.71,0,.5.5,0,0,1,0,.71,60,60,0,0,1-7.62,6.74,76.11,76.11,0,0,0-6.24,5.23h0c-1.53,1.42-3.21,3.06-5.12,5a.5.5,0,1,0,.71.7c1.47-1.5,2.79-2.81,4-4A5.65,5.65,0,0,0,8.12,24c2.33,0,6.25-1.13,11.7-6.51C28.3,9.11,28,2.74,28,2.47Z"
                    style={{ fill: '#02c08e' }}
                  />
                </MinervaLogo>

                <ScLogoText>Minerva</ScLogoText>
              </ScLogo>
            </ScNav>
          </Link>

          <ScSearch>
            {history.location.pathname !== '/' ? (
              <SearchBar
                redirectRoute={
                  `${ROUTES__SEARCH}/${query}` +
                  (channel ? `?channel=${channel}` : '')
                }
              />
            ) : null}
          </ScSearch>

          {(authenticated && user) || profile ? (
            <Link
              to={`${ROUTES__PROFILE}/${user?.paymail || profile?.paymail}`}
              style={{
                flex: 1,
                width: '14rem',
                textAlign: 'right',
                textDecoration: 'none',
                color: '#fff'
              }}
            >
              <ScBitpic
                src={
                  profile?.paymail?.includes('handcash.io')
                    ? `https://cloud.handcash.io/v2/users/profilePicture/${head(
                        profile.paymail.split('@')
                      )}`
                    : `https://bitpic.network/u/${user?.paymail}`
                }
              />
            </Link>
          ) : (
            <ScLink to={ROUTES__AUTHENTICATE}>Login</ScLink>
          )}
        </ScHeaderMax>
      </ScHeader>
      <ScColors>
        <ScColor color="#09f911"></ScColor>
        <ScColor color="#02c08e"></ScColor>
        <ScColor color="#e35bd8"></ScColor>
        <ScColor color="#7a89d6"></ScColor>
        <ScColor color="#70619a"></ScColor>
      </ScColors>
    </>
  )
}

export default Header

const ScColor = styled.div<{ color: string }>`
  background: ${(props) => props.color};
`

const MinervaLogo = styled.svg`
  position: absolute;
  left: 0;
  width: 30px;
`
const MinervaLogoPath = styled.path``
const ScColors = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 1px;
`
const ScLogo = styled.div`
  align-items: center;
  display: flex;
  position: relative;
  width: 240px;
`

const ScNav = styled.div`
  width: 4rem;
  @media (max-width: 768px) {
    width: 2rem;
  }
`

const ScLink = styled(Link)`
  width: 4rem;
  flex: 1;
  text-align: right;
  @media (max-width: 768px) {
    width: 2rem;
  }
`

const ScBitpic = styled.img`
  width: 2.5rem;
  border-radius: 50%;
  margin-right: 0.25rem;
`

const ScHeader = styled.div`
  background-color: #000;
  width: 100%;
`
const ScHeaderMax = styled.div`
  justify-content: space-between;
  max-width: 1700px;
  margin: 0 auto;
  padding: 0.5rem 2rem;
  box-sizing: border-box;
  color: #fff;
  align-items: center;
  display: flex;
`
const ScLogoText = styled.div`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-size: 2.5rem;
  color: #fff;
  text-decoration: none;
  position: absolute;
  left: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`

const ScSearch = styled.div`
  height: 36px;
  flex: 1;
`

import React, { useState, useCallback, useEffect } from 'react'
import './channel.css'
import { Block } from '../block'
import { Card } from '../card'
import { darken } from 'polished'
import { FaRegHeart, FaHeart } from 'react-icons/fa'
import { RouteComponentProps } from 'react-router-dom'
import { ROUTES__PROFILE } from '../../constants'
import { toast } from 'react-toastify'
import { useBmap } from '../../context/bmap/BmapProvider'
import { usePlayer } from '../../context/player/PlayerProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { Video } from 'minerva'
import BitPic from '../bitpic'
import { Script } from 'bsv'
import Linkify from 'react-linkify'
import MetaLens from '../matelens'
import Player from '../player'
import Playlist from '../playlist'
import QRCode from 'qrcode.react'
import styled, { keyframes } from 'styled-components'

// YouTube.PlayerState contains the values that are used by the YouTube IFrame Player API.

type Props = RouteComponentProps<{ channel: string }>

const Channel: React.FC<Props> = ({
  history,
  match: {
    params: { channel: chan }
  }
}) => {
  const { isFetching, error } = useBmap()
  const [liking, setLiking] = useState<boolean>(false)
  const [likeComplete, setLikeComplete] = useState<boolean>(false)

  const {
    tx,
    video,
    youtubeVideo,
    playVideo,
    isLive,
    isLiked,
    pm
  } = usePlayer()
  const { relay } = useRelay()

  const toPaymail = useCallback(
    (e: React.MouseEvent, paymail: string) => {
      history.push(`${ROUTES__PROFILE}/${paymail}`)
    },
    [history]
  )

  const onVideoSelect = useCallback(
    (v: Video, idx: number) => {
      // rearrange video in appStatesPlayed setToVideo(video.id)
      if (v) {
        playVideo(v)
      }
    },
    [playVideo]
  )

  useEffect(() => {
    if (!likeComplete && isLiked) {
      setLikeComplete(isLiked)
    }
  }, [isLiked, likeComplete])

  const clickLike = useCallback(
    async (e: React.MouseEvent, txid: string) => {
      // Record a like on chain
      // temporary
      setLiking(true)
      let data = [
        '1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5',
        'SET',
        'app',
        'minerva',
        'type',
        'like',
        'context',
        'tx',
        'tx',
        txid
      ]

      let s = Script.fromSafeDataArray(
        data.map((d) => {
          return Buffer.from(d)
        })
      )

      try {
        let res = await relay.send({
          outputs: [
            {
              amount: '0',
              currency: 'BSV',
              script: s.toAsmString(),
              signatures: ['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA-BITCOM-HASHED']
            }
          ]
        })
        setLiking(false)
        toast('🦄 Liked! ' + res.txid, {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      } catch (e) {
        console.error(e)
        setLiking(false)
      }
    },
    [relay]
  )

  if (error) {
    return (
      <ScChannel>
        <ScError>Oops, something went wrong</ScError>
      </ScChannel>
    )
  }

  if (isFetching) {
    return (
      <ScChannel>
        <Block color="black" />
      </ScChannel>
    )
  }

  if (!video || !tx || !youtubeVideo) {
    return null
  }

  return (
    <ScChannel>
      <div>
        <Player channel={chan} fullscreen={false}></Player>
        <br />
        {pm ? (
          <Card>
            <ScGrid>
              <ScActions>
                <div
                  onClick={(e) => {
                    if (pm) {
                      toPaymail(e, pm)
                    }
                  }}
                >
                  <BitPic paymail={pm} width="36px" />
                </div>

                <div>&nbsp;</div>
                {liking ? (
                  <ScLiking>
                    <FaRegHeart size="24" />
                  </ScLiking>
                ) : likeComplete ? (
                  <ScLiked>
                    <FaHeart
                      color={darken('.2', 'rgba(237, 28, 36,.5)')}
                      size="24"
                    />
                  </ScLiked>
                ) : (
                  <ScLike
                    onClick={(e) => {
                      clickLike(e, tx?.tx.h || '')
                    }}
                  >
                    <FaRegHeart size="24" />
                  </ScLike>
                )}
              </ScActions>
              <ScVideoTitle>{youtubeVideo?.snippet.title}</ScVideoTitle>
              <ScVideoDetails>
                <Linkify>{youtubeVideo?.snippet.description}</Linkify>
              </ScVideoDetails>
            </ScGrid>
          </Card>
        ) : null}
        <br />

        <Card>
          <MetaLens
            context="videoID"
            subcontext="provider"
            value={youtubeVideo.id}
            subcontextValue="youtube"
            title={`${youtubeVideo.snippet.title} Comments`}
          ></MetaLens>
        </Card>
      </div>

      <ScPlaylist>
        <Playlist
          channel={chan}
          onVideoSelect={onVideoSelect}
          showTitle={true}
          isLive={isLive}
        />
        <br />
        <Card style={{ textAlign: 'center' }}>
          <h5>Scan with RelayX</h5>
          <QRCode
            value={`https://minerva.live/remote/${chan}`}
            bgColor={'#eee'}
            fgColor={'#111'}
            includeMargin={true}
            size={200}
            style={{ margin: 'auto' }}
            level={'M'}
          />
          <h4>Mobile Remote Control</h4>
        </Card>
      </ScPlaylist>
    </ScChannel>
  )
}

export default Channel

const flip = keyframes`
    from {
      transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
      animation-timing-function: ease-out;
    }
    40% {
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
      animation-timing-function: ease-out;
    }
    50% {
      transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
      animation-timing-function: ease-in;
    }
    80% {
      transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
      animation-timing-function: ease-in;
    }
    to {
      transform: perspective(400px);
      animation-timing-function: ease-in;
    }
  `

const spin = keyframes`
    from {
      transform: rotate3d(0, 1, 0, -360deg);
      animation-timing-function: ease-out;
    }
    40% {
      transform: translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
      animation-timing-function: ease-out;
    }
    50% {
      transform: translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
      animation-timing-function: ease-in;
    }
    80% {
      transform: scale3d(0.95, 0.95, 0.95);
      animation-timing-function: ease-in;
    }
    to {
      animation-timing-function: ease-in;
    }
  `

const ScActions = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #222;
  border-radius: 0.25rem;
  padding: 1rem;
  box-sizing: border-box;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
  margin-top: -2.5rem;
  margin-left: -0.5rem;
  background: rgba(112, 97, 154, 0.8);
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
`

const ScPlaylist = styled.div`
  grid-row: 1;
  grid-column: 2;
  @media (max-width: 768px) {
    grid-column: 1;
    grid-row: 2;
  }
`

const ScChannel = styled.div`
  grid-template-columns: 1fr 400px;
  display: grid;
  box-sizing: border-box;
  padding: 2rem;
  max-width: 1700px;
  margin: 0 auto;
  min-height: calc(100vh - 80px);
  grid-gap: 2rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    max-width: 98vw;
    padding: 1rem 0;
  }
`
const ScError = styled.div`
  color: red;
`

const ScVideoDetails = styled.div`
  max-height: 4rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  grid-row: 2;
  grid-column: 1;
  color: #aaa;
`
const ScVideoTitle = styled.div`
  font-weight: bold;
  grid-row: 1;
  grid-column: 1;
`

const ScLike = styled.div`
  cursor: pointer;
`

const ScLiking = styled.div`
  animation: ${spin} 1.2s infinite ease-in-out;
  cursor: pointer;
`

const ScLiked = styled.div`
  animation: ${flip} 1s ease-in-out;
`

const ScGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  grid-template-rows: 20px 1fr;
  grid-gap: 1.5rem;
`

import styled, { keyframes } from 'styled-components'

const rotateplane = keyframes`
  0% { transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
`

export const Block = styled.div<{ color: string }>`
  margin: 100px auto;
  animation: ${rotateplane} 1.2s infinite ease-in-out;
  background: ${(props) => props.color};
  width: 1.5rem;
  height: 1.5rem;
  animation-duration: 1.2s;
`

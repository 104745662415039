import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useBmap, State } from '../../context/bmap/BmapProvider'
import { Link } from 'react-router-dom'
import { ROUTES__CHANNEL } from '../../constants'
import styled from 'styled-components'
import { Block } from '../block'

type Props = RouteComponentProps

// https://i.ytimg.com/vi/zio9QCbprrI/hq720.jpg?sqp=-oaymwEZCK4FEIIDSEbyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCNmhVI0cg5UCd4ainWp8QfaddYOg
const thumbnailSrc = (s: State) => {
  // https://img.youtube.com/vi/<insert-youtube-video-id-here>/0.jpg
  // https://img.youtube.com/vi/<insert-youtube-video-id-here>/1.jpg
  // https://img.youtube.com/vi/<insert-youtube-video-id-here>/2.jpg
  // https://img.youtube.com/vi/<insert-youtube-video-id-here>/3.jpg
  return `https://img.youtube.com/vi/${s.playlist[0]?.videoID}/0.jpg`
}

const channels = (states: State[]) => {
  return states.map((s) => {
    return (
      <ScChannel key={s.channel}>
        <Link
          to={`${ROUTES__CHANNEL}/${s.channel}`}
          style={{ textDecoration: 'none', color: '#FFF' }}
        >
          <ScThumbContain>
            <ScThumb alt={s.channel} src={thumbnailSrc(s)} />
            <ScChannelInfo>
              <ScChannelName>{s.channel}</ScChannelName>
              {s.player ? <ScLiveIndicator></ScLiveIndicator> : null}
            </ScChannelInfo>
          </ScThumbContain>
        </Link>
      </ScChannel>
    )
  })
}

const Channels: React.FC<Props> = (props) => {
  const { appStates, appStatesPlayed, isFetching, error } = useBmap()
  const focusSearch = () => {
    document.getElementById('search-input')?.focus()
  }

  // Featured channel names
  const featured = [
    'cars',
    'nature',
    'bitcoin',
    'gaming',
    'technology',
    'comedy',
    'music',
    'news'
  ]

  if (isFetching || !appStatesPlayed || !appStates) {
    return <Block color={'#000'}></Block>
  }

  if (error) {
    return <ScChannels>Couldn't load channels. Try again.</ScChannels>
  }

  return (
    <ScChannelsPage>
      <div>
        {appStates.length ? (
          <ScTitle>Active Channels</ScTitle>
        ) : (
          <>
            <ScNoActiveChannels onClick={focusSearch}>
              There are no live channels. Play something!
            </ScNoActiveChannels>
            <ScTitle>Featured Channels</ScTitle>
            <ScChannels>
              {/* Featured Channels */}
              {channels(
                appStates.concat(appStatesPlayed).filter((s) => {
                  return featured.indexOf(s.channel) !== -1
                })
              )}
            </ScChannels>
          </>
        )}
        {/* Active Channels */}
        <ScChannels>{channels(appStates)}</ScChannels>
        {/* <ScTitle>All Channels</ScTitle>
        <ScChannels>{channels(appStatesPlayed, false)}</ScChannels> */}
      </div>
    </ScChannelsPage>
  )
}

export default Channels

const ScTitle = styled.h4`
  font-size: 1.25rem;
  letter-spacing: 0.1rem;
  font-weight: 400;
  text-align: left;
  max-width: 1480px;
  margin: 0 auto;
  padding: 1rem 2rem;
  opacity: 0.3;
  color: #fff;
`

const ScNoActiveChannels = styled.div`
  display: block;
  padding: 2rem;
  background-color: #111;
  color: #02c08e;
  border-radius: 0.25rem;
  max-width: 400px;
  margin: 4rem auto;
  cursor: pointer;
  text-align: center;
`
const ScLiveIndicator = styled.div`
  z-index: 10;
  background-color: #09f911;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
`

const ScChannelsPage = styled.div`
  padding: 2rem;
  max-width: 1480px;
  margin: auto;
  min-height: calc(100vh - 80px);
`

const ScChannels = styled.ul`
  box-sizing: border-box;
  display: grid;
  grid-gap: 2rem 0.25rem;
  grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
  margin: auto 2rem;
  margin-bottom: 3rem !important;
  justify-content: space-evenly;
  max-width: 1480px;
  padding: 0;
`

const ScChannelName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 600;
  letter-spacing: 0.04rem;
  text-transform: capitalize;
  width: 100%;
  text-align: left;
  display: block;
  z-index: 1;
`

const ScChannelInfo = styled.div`
  border-bottom: 1px solid #000;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 1rem;
  transition: color 0.25s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ScThumbContain = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 2;
`
const ScChannel = styled.li`
  align-items: center;
  cursor: pointer;
  list-style: none;
  font-size: 1rem;
  font-family: 'ubuntu-bold', Lato, sans-serif;
  text-align: center;
  transition: all 0.25s;
  min-height: 180px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
  &:hover {
    border-bottom: 1px solid #02c08e;
  }
`

const ScThumb = styled.img`
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
  min-height: 180px;
  background-image: url(/images/no.image.png);
  background-size: contain;
  width: 100%;
  transition: all 0.25s ease-in-out;
`

import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import Playlist from '../playlist'

type Props = RouteComponentProps<{ channel: string }>

const Remote: React.FC<Props> = ({
  match: {
    params: { channel }
  }
}) => {
  const onVideoSelect = () => {
    console.log('video selected')
  }
  return (
    <ScRemote>
      <ScTitle>{channel}</ScTitle>
      <Playlist
        channel={channel}
        onVideoSelect={onVideoSelect}
        isLive={true}
        mobile={true}
        showTitle={false}
      ></Playlist>
    </ScRemote>
  )
}

export default Remote

const ScTitle = styled.div`
  box-sizing: border-box;
  font-size: 1.25rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
  padding: 0 1rem;
  text-transform: uppercase;
`

const ScRemote = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: 720px;
  margin: auto;
`

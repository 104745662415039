import styled from 'styled-components'
import { lighten, darken, desaturate } from 'polished'
import { theme } from '../../theme/theme'
export const Button = styled.button<{
  color?: string
  background?: string
  borderRadius?: string
  width?: string
}>`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  flex: 1;
  color: ${(props) => (props.color ? props.color : theme.colors.white)};
  background-color: ${(props) =>
    props.background ? props.background : theme.colors.primary};
  border: 0;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '0.25rem'};
  cursor: pointer;
  text-transform: uppercase;
  min-width: ${(props) => (props.width ? props.width : 'unset')};
  justify-content: center;
  display: flex;
  transition: color 0.5s, background 0.5s;
  &:hover {
    color: ${(props) => lighten('.1', props.color ? props.color : '#7a89d6')};
    background: ${(props) =>
      darken('.1', props.background ? props.background : theme.colors.primary)};
  }
  &:disabled {
    cursor: default;
    background: ${(props) =>
      darken(
        '0.5',
        desaturate(
          '.5',
          props.background ? props.background : theme.colors.primary
        )
      )};
  }
`

import React from 'react';

const RelayIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg fill="none" x="0px" y="0px" viewBox="0 0 20 20" enableBackground="new 0 0 20 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.76127 2.39441C3.92259 2.39441 -3.05176e-05 6.33549 -3.05176e-05 11.1972C-3.05176e-05 16.0588 3.92259 20.0001 8.76127 20.0001C13.6001 20.0001 17.5226 16.0588 17.5226 11.1972C17.5226 6.33549 13.6001 2.39441 8.76127 2.39441ZM8.76128 4.50701C12.4329 4.50701 15.4199 7.50822 15.4199 11.1972C15.4199 14.8861 12.4329 17.8873 8.76128 17.8873C5.08982 17.8873 2.10263 14.8861 2.10263 11.1972C2.10263 7.50822 5.08982 4.50701 8.76128 4.50701Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1839 0C6.34518 0 2.42256 3.94108 2.42256 8.80282C2.42256 13.6644 6.34518 17.6056 11.1839 17.6056C16.0227 17.6056 19.9452 13.6644 19.9452 8.80282C19.9452 3.94108 16.0227 0 11.1839 0ZM11.7182 2.1126C15.3898 2.1126 18.3769 5.11381 18.3769 8.8028C18.3769 12.4917 15.3898 15.4929 11.7182 15.4929C8.04678 15.4929 5.0596 12.4917 5.0596 8.8028C5.0596 5.11381 8.04678 2.1126 11.7182 2.1126Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.73712 1.89014L2.40312 5.1496L5.12432 4.10383L5.73712 1.89014Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.5569 17.8635L17.7563 14.4706L15.0796 15.6266L14.5569 17.8635Z"
      fill="currentColor"
    />
  </svg>
);

export default RelayIcon;

import styled from 'styled-components'

export const Authenticating = styled.div`
  color: #eee;
  margin: 2rem auto;
  background: #111;
  max-width: 720px;
  border-radius: 0.25rem;
  padding: 2rem;
  text-align: center;
`

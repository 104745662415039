import React, { useCallback, useState, useEffect } from 'react'
import { Video, YouTubeVideo } from 'minerva'
import styled from 'styled-components'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { Script } from 'bsv'
import { BmapTx } from 'bmapjs'
import { useRelay } from '../../../context/relay/RelayProvider'
import { useBmap } from '../../../context/bmap/BmapProvider'
import { IoMdThumbsUp, IoMdThumbsDown } from 'react-icons/io'

const VideoListItem: React.FC<{
  video?: Video
  onVideoSelect?: Function
  showVoting?: boolean
  mobile?: boolean
  youtubeData?: YouTubeVideo
}> = (props) => {
  // switch (props.video.provider) {
  //   case 'youtube':
  //     console.log('Render youtube video')
  //     break
  // }
  const [youTubeVideo, setYouTubeVideo] = useState<YouTubeVideo | null>(
    props.youtubeData || null
  )
  const [videoTx, setVideoTx] = useState<BmapTx | null>(null)
  const { getVideoByTxID } = useBmap()
  const [busy, setBusy] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      if (props.video) {
        try {
          let videoData = await getVideoByTxID(props.video.txid)
          if (videoData) {
            const { videoTx: vtx, providerData } = videoData
            setVideoTx(vtx)
            setYouTubeVideo(providerData)
          }
        } catch (e) {
          console.error('failed to get video by txid', e)
        }
        setBusy(false)
      }
    }
    if (props.video && !youTubeVideo && !busy) {
      setBusy(true)
      fetchData()
    }
  }, [props.video, getVideoByTxID, youTubeVideo, busy])

  // const imageUrl = `https://i.ytimg.com/vi/${props.youTubeVideo.id}/hq720.jpg`
  const imageUrl = youTubeVideo?.snippet.thumbnails.maxres
    ? youTubeVideo?.snippet.thumbnails.maxres.url
    : youTubeVideo?.snippet.thumbnails.high
    ? youTubeVideo?.snippet.thumbnails.high.url
    : youTubeVideo?.snippet.thumbnails.default.url
  const { relay } = useRelay()
  TimeAgo.addLocale(en)
  const timeAgo = new TimeAgo('en-US')

  const date = new Date(videoTx?.blk ? videoTx.blk.t * 1000 : 0)

  const vote = useCallback(
    async (tx: string, upvote: boolean) => {
      // ;<div
      //   class="money-button"
      //   data-outputs='[{
      //   "script": " 21e8 OP_SIZE OP_4 OP_PICK OP_SHA256 OP_SWAP OP_SPLIT OP_DROP OP_EQUALVERIFY OP_DROP OP_CHECKSIG",
      //   "amount": "2.18",
      //   "currency": "USD"
      // }]'
      // ></div>

      console.log('clicked video', tx)
      // Record a like on chain
      // temporary
      setBusy(true)
      let data = [
        '1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5',
        'SET',
        'app',
        'minerva',
        'type',
        'upvote',
        'context',
        'tx',
        'tx',
        tx
      ]

      let s = Script.fromSafeDataArray(
        data.map((d) => {
          return Buffer.from(d)
        })
      )

      try {
        let res = await relay.send({
          outputs: [
            {
              amount: '0',
              currency: 'BSV',
              script: s.toAsmString(),
              signatures: ['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA-BITCOM-HASHED']
            },
            {
              // todo - is it strange that this isnt signed?
              amount: '0.00000700', // todo - adjust
              currency: 'BSV',
              script: `${tx} ${
                upvote ? '1f44d0' : '1f44e0'
              } OP_SIZE OP_4 OP_PICK OP_SHA256 OP_SWAP OP_SPLIT OP_DROP OP_EQUALVERIFY OP_DROP OP_CHECKSIG`
            }
          ]
        })
        // localStorage.setItem(
        //   `minerva.${upvote ? 'upvote' : 'downvote'}.${tx}`,
        //   String(new Date().getTime())
        // )
        setBusy(false)
        console.log('voted!', res.txid)
        // toast(`🦄 ${upvote ? 'upvoted' : 'downvoted'}! ${res.txid}`, {
        //   position: 'bottom-right',
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true
        // })
      } catch (e) {
        console.error(e)
        setBusy(false)
      }
    },
    [relay]
  )

  const upvote = useCallback(
    (e: React.MouseEvent, tx: string) => {
      e.stopPropagation()
      console.log('upvote!!', tx)
      vote(tx, true)
    },
    [vote]
  )

  const downvote = useCallback(
    (e: React.MouseEvent, tx: string) => {
      e.stopPropagation()
      console.log('downvote!', tx)
      vote(tx, false)
    },
    [vote]
  )

  const isBusy = () => {
    return busy
  }

  return (
    <ScLi
      mobile={props.mobile || false}
      cursor={props.onVideoSelect ? 'pointer' : undefined}
      onClick={() => {
        if (props.onVideoSelect) {
          // When list is initialized with provider data, we send the provider data instead of the video item
          props.onVideoSelect(
            props.youtubeData ? props.youtubeData : props.video
          )
        }
      }}
    >
      <div>
        <ScThumbnail
          mobile={props.mobile}
          alt={youTubeVideo?.snippet.title || ''}
          src={imageUrl}
        />
      </div>
      <div>
        <ScTitle mobile={props.mobile}>{youTubeVideo?.snippet.title}</ScTitle>
        <ScTextMuted mobile={props.mobile}>
          {props.video ? (
            <div> {timeAgo.format(date)} </div>
          ) : (
            timeAgo.format(new Date(youTubeVideo?.snippet.publishedAt || ''))
          )}
        </ScTextMuted>
        <ScVoting mobile={props.mobile}>
          <ScUpvote
            disabled={isBusy()}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (!busy && props.video?.txid) {
                upvote(e, props.video.txid)
              }
            }}
          >
            <IoMdThumbsUp />
          </ScUpvote>
          <ScDownvote
            disabled={isBusy()}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              if (!busy && props.video) {
                downvote(e, props.video.txid)
              }
            }}
          >
            <IoMdThumbsDown />
          </ScDownvote>
        </ScVoting>
      </div>
    </ScLi>
  )
}

export default VideoListItem

const ScLi = styled.li<{ mobile?: boolean; cursor?: string }>`
  cursor: ${(props) => (props.cursor ? props.cursor : 'unset')};
  list-style: none;
  display: flex;
  flex-direction: ${(props) => (props.mobile ? 'column' : 'row')};
`

const ScThumbnail = styled.img<{ mobile?: boolean }>`
  margin-right: 8px;
  height: ${(props) => (props.mobile ? 'unset' : '94px')};
  width: ${(props) => (props.mobile ? '100%' : '168px')};
`

const ScVoting = styled.div<{ mobile?: boolean }>`
  padding: ${(props) => (props.mobile ? '.25rem 1rem' : '.25rem')};
  display: flex;
`
const ScUpvote = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  color: #666;
  margin-right: 0.25rem;
  &:disabled {
    color: #222;
  }
  &:hover {
    color: #f2f2f2;
  }
`

const ScDownvote = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
  color: #666;
  &:disabled {
    color: #222;
  }
  &:hover {
    color: #f2f2f2;
  }
`

const ScTextMuted = styled.small<{ mobile?: boolean }>`
  padding: ${(props) => (props.mobile ? '0 1rem' : '0')};
  color: #aaa;
  display: flex;
`

const ScTitle = styled.h3<{ mobile?: boolean }>`
  padding: ${(props) => (props.mobile ? '0 1rem' : '0')};
  margin: 0 0 4px 0;
  display: -webkit-box;
  max-height: 2.6rem;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2rem;
  font-family: Roboto, Arial, sans-serif;
`

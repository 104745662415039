import { BmapTx } from 'bmapjs'
import React, { useCallback, useContext, useMemo, useState } from 'react'
// import { useQuery } from 'react-query'
// import specialFetch, { useQueryConfig } from '../../utils/fetch'
import { useRelay } from '../relay/RelayProvider'

export enum UserStatus {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export type User = {
  avatar: string
  username: string
  paymail: string
  timestamp: number
}

type ContextValue = {
  user: User | null
  isFetching: boolean
  error: Error | null
  following: BmapTx[] | null
  getFollowing: (paymail: string) => Promise<void>
  paymail: string | null
}

interface Props {}

const UserContext = React.createContext<ContextValue | undefined>(undefined)

const UserProvider: React.FC<Props> = (props) => {
  const { paymail } = useRelay()
  const [following, setFollowing] = useState<BmapTx[] | null>(null)

  const getFollowing = useCallback(
    async (pm?: string) => {
      let and: any[] = [
        { 'MAP.type': 'follow' },
        { '15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA.s': paymail }
      ]

      if (pm) {
        and.push({ 'MAP.paymail': pm })
      }

      let query = {
        v: 3,
        q: {
          find: {
            $and: and
          },
          limit: 10,
          project: {
            out: 0,
            in: 0
          }
        }
      }

      const b64 = Buffer.from(JSON.stringify(query)).toString('base64')
      try {
        let res = await fetch('https://b.map.sv/q/' + b64)
        let j = await res.json()
        console.log('gotem!', j)

        setFollowing((j.u || []).concat(j.c || []))
      } catch (e) {
        console.error(e)
      }
    },
    [paymail, setFollowing]
  )

  // const { data: user, isFetching, error } = useQuery<User, any>(
  //   paymail ? ['profile', paymail] : null,
  //   () =>
  //     specialFetch(`${process.env.REACT_APP_DOMAIN_PATH}/functions/profile`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ paymail }),
  //     }),
  //   { ...useQueryConfig, retry: false }
  // )
  const isFetching = false
  const error = null
  const user: User = {
    avatar: 'https://...',
    username: 'Satchmo',
    paymail: paymail ? paymail : '',
    timestamp: new Date().getTime() / 1000
  }

  const value = useMemo(
    () => ({
      user,
      isFetching,
      error,
      following,
      getFollowing,
      paymail
    }),
    [user, isFetching, error, following, getFollowing, paymail]
  )

  return <UserContext.Provider value={value} {...props} />
}

const useUser = (): ContextValue => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider')
  }
  return context
}

export { UserProvider, useUser }

import VideoList from './video-list'
import { YouTubeVideo } from 'minerva'
import { useYouTube } from '../../context/youtube/YouTubeProvider'
import { history } from '.././../context/router/history'
import { ROUTES__POST } from '../../constants'
import React, { useState, useEffect } from 'react'
import { Block } from '../block'
import { Card } from '../card'

interface Props {}

const FindYouTubeVideo: React.FC<Props> = (props) => {
  // ToDo - Support Multiple Provi
  const { youTubeVideos, errorSearch, isFetching } = useYouTube()
  const [selectedVideo, setSelectedVideo] = useState<YouTubeVideo>()

  // if (selectedVideo) {
  //   console.log(
  //     'redirecting',
  //     `${ROUTES__POST}/youtube/${selectedVideo.id}${window.location.search}`
  //   )
  //   return (
  //     <Redirect
  //       to={`${ROUTES__POST}/youtube/${selectedVideo.id}${window.location.search}`}
  //     ></Redirect>
  //   )
  // }

  useEffect(() => {
    if (selectedVideo) {
      history.push(
        `${ROUTES__POST}/youtube/${selectedVideo.id}${window.location.search}`
      )
    }
  }, [selectedVideo])
  if (isFetching) {
    return <Block color={'#000'}></Block>
  }

  if (errorSearch) {
    return <Card>{errorSearch.message}</Card>
  }

  if (!youTubeVideos) {
    return null
  }

  return (
    <VideoList
      onVideoSelect={(youTubeVideo: YouTubeVideo) => {
        console.log('set selected', youTubeVideo)
        setSelectedVideo(youTubeVideo)
      }}
      youTubeVideos={youTubeVideos}
    />
  )
}

export default FindYouTubeVideo

const followedVideosQuery = (myPaymail: string) => {
  return {
    v: 3,
    q: {
      aggregate: [
        {
          $match: {
            'MAP.type': 'video'
          }
        },
        {
          $project: {
            tx: 1,
            MAP: 1,
            '15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA': 1
          }
        },
        {
          $lookup: {
            from: 'c',
            foreignField: 'MAP.paymail',
            localField: '15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA.0.4.s',
            as: 'follows'
          }
        },
        {
          $match: {
            $and: [
              {
                'follows.MAP.type': 'follow'
              },
              {
                'follows.15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA.4.s': myPaymail
              }
            ]
          }
        },
        {
          $project: {
            follows: 0
          }
        }
      ]
    }
  }
}

const likedVideosQuery = (myPaymail: string) => {
  return {
    v: 3,
    q: {
      aggregate: [
        {
          $match: {
            'MAP.type': 'video'
          }
        },
        {
          $lookup: {
            from: 'c',
            localField: 'tx.h',
            foreignField: 'MAP.tx',
            as: 'likes'
          }
        },
        {
          $and: [
            {
              $match: {
                'likes.MAP.type': 'like'
              }
            },
            {
              'likes.15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA.4.s': myPaymail
            }
          ]
        },
        {
          $project: {
            likes: 0
          }
        }
      ]
    }
  }
}

export { likedVideosQuery, followedVideosQuery }

import React from 'react'
import styled from 'styled-components'
import { ROUTES__PROTOCOL, ROUTES__HELP } from '../../constants'
import { Link } from 'react-router-dom'

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <ScFooter>
      <span>&copy; 2020 Minerva</span>
      <br />
      <ScLinkList>
        <Link
          to={ROUTES__PROTOCOL}
          style={{
            padding: '.25rem'
          }}
        >
          Protocol
        </Link>
        <span>•</span>
        <Link
          to={ROUTES__HELP}
          style={{
            padding: '.25rem'
          }}
        >
          Help
        </Link>
        <span>•</span>
        <a
          href={'https://en.wikipedia.org/wiki/Free_Speech_Flag'}
          rel="noopener noreferrer"
          target="_blank"
          style={{
            padding: '.25rem'
          }}
        >
          Colors
        </a>
      </ScLinkList>
    </ScFooter>
  )
}

export default Footer

const ScLinkList = styled.div`
  display: inline-block;
`
const ScFooter = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  color: #666;
`

import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import FindVideo from '../find-video'
import { useYouTube } from '../../context/youtube/YouTubeProvider'
import { Block } from '../block'
type Props = RouteComponentProps<{ query: string }>

const Search: React.FC<Props> = ({
  match: {
    params: { query }
  }
}) => {
  const { search, isFetching, error } = useYouTube()

  const [lastQuery, setLastQuery] = useState<string>('')

  useEffect(() => {
    async function fireSearch() {
      try {
        await search(query)
      } catch (e) {
        console.log('bad search response', e)
        console.error(e)
      }
    }
    if (lastQuery !== query) {
      setLastQuery(query)
      fireSearch()
    }
  }, [lastQuery, search, query])

  if (isFetching) {
    return (
      <ScSearchResults>
        <Block color={'#000'}></Block>
      </ScSearchResults>
    )
  }

  if (error) {
    return <ScSearchResults>Something went wrong.</ScSearchResults>
  }

  return (
    <ScSearchResults>
      <FindVideo></FindVideo>
    </ScSearchResults>
  )
}

export default Search

const ScSearchResults = styled.div`
  max-width: 1480px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 1rem;
    max-width: 98vw;
  }
`

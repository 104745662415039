import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { Card } from '../card'

type Props = RouteComponentProps<{ paymail: string }>

const Protocol: React.FC<Props> = ({
  match: {
    params: { paymail }
  }
}) => {
  return (
    <ScProtocol>
      <Card>
        <ScTitle>Minerva Protocol</ScTitle>
        <p>
          Minerva uses{' '}
          <a href="https://github.com/rohenaz/map">Magic Attribute Protocol</a>{' '}
          to record actions to the blockchain and signs them with Paymail
          Signature Protocol. All values are strings. Each OP_RETURN output has
          a paymail signature appended.
        </p>
        <h2>MAP</h2>
        <h3>Video:</h3>
        <p>
          Output 1: MAP SET app minerva type video provider youtube start 0
          duration 337 | SIG
        </p>
        <p>Output 2: MAP ADD tags channelName | SIG</p>
        <ul>
          <li>
            'provider' can be one of ['youtube', 'vimeo', 'magnet', 'streamity']
          </li>
          <li>
            start - the number of seconds to skip over when playing a video
          </li>
          <li>duration - the number of seconds to play the video</li>
          <li>tags - used to define which channels will include the video</li>
        </ul>
        <h3>Like / Tip</h3>
        <p>
          Like a comment by txid: MAP SET app minerva type like context tx tx
          *txid*
        </p>
        <br />
        <a
          href="https://api.minerva.live/query/eyJ2IjozLCJxIjp7ImZpbmQiOnsiTUFQLmFwcCI6ICJtaW5lcnZhIiwgIk1BUC50eXBlIjogImxpa2UifX19"
          target="_blank"
          rel="noreferrer noopener"
        >
          Example
        </a>
        <span>
          video by ID: MAP SET app minerva type like context videoID videoID
          *the_ID* subcontext provider provider *youtube, vimeo, magnet* |
          SIGNATURE
        </span>
        <h3>Follow / Subscript to user</h3>
        MAP SET app minerva type follow context paymail paymail *paymail* |
        SIGNATURE
        <br />
        <a
          href="https://api.minerva.live/query/eyJ2IjozLCJxIjp7ImZpbmQiOnsiTUFQLmFwcCI6ICJtaW5lcnZhIiwgIk1BUC50eXBlIjogImZvbGxvdyJ9fX0="
          target="_blank"
          rel="noreferrer noopener"
        >
          Example
        </a>
        <h3>Comment</h3>
        <p>
          Comments are provided via <a href="https://metalens.app">MetaLens</a>
          which follows the same general protocol structure A reply to a post by
          txid: MAP SET app metalens type post context tx tx *txid*
        </p>
        <h3>Channel</h3>
        To create a new channel, simply post a video with your new channel name.
        <br />
        <br />
        <h1>Note</h1>
        <p>
          While Minerva protocol is open for anyone to submit new content from
          their own user interfaces, we do place restrictions on which BSV
          transactions we will index. Everyone is free to create a server to
          index the blockchain with their own interpretation of channel states.
        </p>
        <h3>Ethical Rules for Inclusion</h3>
        <p>
          We will comply with all laws and takedown requests, so anything
          illegal that comes to our attention will not be indexed by Minerva.
        </p>
        <h3>Economic Rules for Inclusion</h3>
        <p>
          If you post a video that has already been posted in a channel, a fee
          must be included to the last person to play it.
        </p>
        <a href="https://api.minerva.live/query/eyJ2IjozLCJxIjp7ImZpbmQiOnsiTUFQLmFwcCI6ICJtaW5lcnZhIn19fQ==">
          More Examples
        </a>
        <div>
          notes Econic Rules - Votes pay the person 40% and POW 50% Minerva 10%
          - Likes pay the poster, and any tagged contributors, and the last 10
          people who liked this item - Comments Minerva 1c, metalens 10% of the
          1c - Needed params: cost, pay
        </div>
      </Card>
    </ScProtocol>
  )
}

export default Protocol

const ScTitle = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #aaa;
  width: 100%;
  margin: 0.5rem 0;
`

const ScProtocol = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 720px;
  margin: auto;
`

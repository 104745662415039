import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { ROUTES__AUTHENTICATE, ROUTES__CHANNELS } from '../../constants'
import { useBmap } from '../../context/bmap/BmapProvider'
import { useHandcash } from '../../context/handcash/HandcashProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { Block } from '../block'
import { Button } from '../button'
import HandcashIcon from '../icons/HandcashIcon'
import RelayIcon from '../icons/RelayIcon'

type Props = RouteComponentProps<any>

const Landing: React.FC<Props> = (props) => {
  const { isFetching, error } = useBmap()
  const { setAuthToken, getProfile, profile } = useHandcash()
  const { authenticated } = useRelay()

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('authToken')) {
      let token = searchParams.get('authToken')
      if (token) {
        setAuthToken(token)
        getProfile()
      }
    }
  }, [getProfile, setAuthToken])

  if (authenticated || profile) {
    props.history.push(ROUTES__CHANNELS)
  }

  if (error) {
    return (
      <ScLanding>
        <ScError>Oops, something went wrong</ScError>
      </ScLanding>
    )
  }

  if (isFetching) {
    return (
      <ScLanding>
        <Block color="blue" />
      </ScLanding>
    )
  }

  return (
    <ScLanding>
      <ScTitle>
        A <span style={{ color: '#09f911' }}>Collaborative</span>{' '}
        <span style={{ color: '#7a89d6' }}>Video Curation Machine</span> on{' '}
        <span style={{ color: '#e35bd8' }}>Bitcoin SV</span>
      </ScTitle>
      <br />
      <ScFeatureList>
        <li>Infinite channels</li>
        <li>Timely news and information</li>
        <li>Real-time group chat</li>
        <li>Earn Bitcoin for sharing</li>
      </ScFeatureList>
      Minerva supports RelayX and Handcash Wallets for login and recording
      interactions to the BitCoin blockchain.
      <br />
      <div style={{ display: 'flex', marginTop: '1rem' }}>
        <div
          style={{
            flex: '1'
          }}
        >
          <Button
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: 'auto'
            }}
            onClick={() => {
              props.history.push(`${ROUTES__AUTHENTICATE}`)
            }}
          >
            <RelayIcon style={{ width: '2rem', marginRight: '.5rem' }} /> Sign
            In with Relay
          </Button>
        </div>
        <div
          style={{
            flex: '1'
          }}
        >
          <Button
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              margin: 'auto'
            }}
            background={`green`}
            onClick={() => {
              window.location.href = `https://us-central1-minerva-live.cloudfunctions.net/hcLogin`
            }}
          >
            <HandcashIcon style={{ width: '2rem', marginRight: '.5rem' }} />
            Sign In with Handcash
          </Button>
        </div>
      </div>
    </ScLanding>
  )
}

export default Landing

const ScFeatureList = styled.ul``
const ScTitle = styled.h2`
  font-size: 1.75rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
`

const ScError = styled.div`
  color: pink;
`

const ScLanding = styled.div`
  color: #eee;
  margin: 2rem auto;
  background: #111;
  max-width: 720px;
  border-radius: 0.25rem;
  padding: 2rem;
`

const ScRelay = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
`

const ScRelayLogo = styled.img`
  width: 120px;
`

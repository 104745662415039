import { DefaultTheme } from 'styled-components/macro'

export const theme: DefaultTheme = {
  colors: {
    primary: '#007edb',
    success: '#1eaa59',
    successLight: '#5cd1a5',
    error: '#f5334a',
    errorLight: '#ff5b5b',
    black: '#000',
    white: '#fff',
    grey: '#f2f2f2'
  }
}

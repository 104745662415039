import React from 'react'
import { Router } from 'react-router'
import { ThemeProvider } from 'styled-components'
import { theme } from '../theme/theme'
import { BitsocketProvider } from './bitsocket/BitsocketProvider'
import { BmapProvider } from './bmap/BmapProvider'
import { HandcashProvider } from './handcash/HandcashProvider'
import { PlayerProvider } from './player/PlayerProvider'
import { RelayProvider } from './relay/RelayProvider'
import { history } from './router/history'
import { UserProvider } from './user/UserProvider'
import { YouTubeProvider } from './youtube/YouTubeProvider'

interface Props {}

export const AppProviders: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={theme}>
    <BmapProvider>
      <BitsocketProvider>
        <Router history={history}>
          <RelayProvider>
            <HandcashProvider>
              <UserProvider>
                <PlayerProvider>
                  <YouTubeProvider>{children}</YouTubeProvider>
                </PlayerProvider>
              </UserProvider>
            </HandcashProvider>
          </RelayProvider>
        </Router>
      </BitsocketProvider>
    </BmapProvider>
  </ThemeProvider>
)

import React from 'react'
import styled from 'styled-components'
import VideoListItem from '../find-video/video-list-item'
import { Video } from 'minerva'
import { useBmap } from '../../context/bmap/BmapProvider'
import { Card } from '../card'
import { BsShuffle } from 'react-icons/bs'

const Playlist: React.FC<{
  channel: string
  onVideoSelect: Function
  isLive: boolean
  mobile?: boolean
  showTitle?: boolean
}> = (props) => {
  const { channel, channelPlayed } = useBmap()

  const playlist = channel(props.channel)?.playlist
  const playedVideos = channelPlayed(props.channel)?.playlist

  const focusSearch = () => {
    document.getElementById('search-input')?.focus()
  }

  if (!playlist || !playedVideos) {
    return <p>No videos :(</p>
  }
  console.log('playlist length', playlist.length)
  return (
    <Card mobile={props.mobile}>
      {props.showTitle ? (
        <ScTitle mobile={props.mobile}>Up next</ScTitle>
      ) : null}

      {playlist.length > 1 ? (
        playlist?.map((video: Video, idx: number) => {
          return idx === 0 || !video ? null : (
            <VideoListItem
              video={video}
              key={video.txid}
              showVoting={true}
              mobile={props.mobile}
            />
          )
        })
      ) : (
        <ScFromHistory onClick={focusSearch}>
          <BsShuffle color="#09f911" />
          <br />
          <br />
          Shuffling from history
          <br />
          <small>No videos on deck. Play Something!</small>
        </ScFromHistory>
      )}
      {props.isLive ? <div>{/*CHAT!!!*/}</div> : null}
      {playedVideos.length && !props.isLive ? (
        <>
          <ScHr />
          <ScTitle>Recently Played</ScTitle>
        </>
      ) : null}
      {!props.isLive && playedVideos.length
        ? playedVideos.map((video: Video, idx: number) => {
            // ToDo - Multiple video providers
            return (
              <VideoListItem
                video={video}
                key={video.txid}
                onVideoSelect={() => {
                  props.onVideoSelect(video, idx)
                }}
                mobile={props.mobile}
              />
            )
          })
        : null}
    </Card>
  )
}
export default Playlist

const ScFromHistory = styled.div`
  padding: 2rem;
  background-color: #222;
  border-radius: 0.25rem;
  text-align: center;
`

const ScHr = styled.hr`
  border: 0;
  background: #7a89d6;
  height: 1px;
  width: 100%;
  margin: 2rem 0 2rem 0;
`

const ScTitle = styled.div<{ mobile?: boolean }>`
  padding: ${(props) => (props.mobile ? '0 1rem' : '0')};
  font-family: Roboto, Arial, sans-serif;
  font-size: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
`

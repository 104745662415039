import { BmapTx } from 'bmapjs'
import bops from 'bops'
import { Script } from 'bsv'
import { lighten } from 'polished'
import React, { useCallback, useEffect, useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import { Tag, WithContext as ReactTags } from 'react-tag-input'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { ROUTES__CHANNEL } from '../../constants'
import { useBmap } from '../../context/bmap/BmapProvider'
import { useHandcash } from '../../context/handcash/HandcashProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import { history } from '../../context/router/history'
import { useYouTube } from '../../context/youtube/YouTubeProvider'
import { Button } from '../button'
import { Card } from '../card'
import './tags.css'

const PostForm: React.FC<{
  article?: BmapTx
  mode?: string
  channel?: string
  value?: string
  duration: string
  start?: string
}> = (props) => {
  const [videoInputValue, setVideoInputValue] = useState<string>(
    props.value || ''
  )

  const params = new URLSearchParams(window.location.search)
  let channel = params.get('channel')
  const { authToken } = useHandcash()
  const { relay, authenticated } = useRelay()
  const { youTubeVideo } = useYouTube()
  const [tags, setTags] = useState<Tag[]>(
    channel && channel.length ? [{ id: channel, text: channel }] : []
  )
  const [redirect, setRedirect] = useState<boolean>(false)

  const [showAdvanced, setShowAdvanced] = useState<boolean>(false)
  const [usd, setUsd] = useState<string>('$0.00')
  const { appStates, appStatesPlayed } = useBmap()

  const channels = appStates?.concat(appStatesPlayed || []).map((s) => {
    return { id: s.channel, text: s.channel }
  }) as Tag[]

  const [start, setStart] = useState<string>(props.start || '0')
  const [duration, setDuration] = useState<string>(props.duration)

  const toggleAdvanced = () => {
    setShowAdvanced(!showAdvanced)
  }

  const clearTags = useCallback(() => {
    setTags([])
  }, [])

  const handleStartChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    min?: number,
    max?: number
  ) => {
    console.log('value changed for an input', event, event.target.id)
    if (min && parseInt(event.target.value) < min) {
      return
    }
    if (max && parseInt(event.target.value) > max) {
      return
    }
    setStart(event.target.value)
  }

  const calculatePrice = useCallback((duration: number) => {
    // 2 penny minimum
    let minimum = 0.02
    // Rate is 1/100th of a penny per second
    let amt = duration * 0.00001
    return amt < minimum ? minimum : amt
  }, [])

  useEffect(() => {
    let d = parseInt(duration)
    if (d > 0) {
      // USD Formatting
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      setUsd(formatter.format(calculatePrice(d)))
    }
  }, [calculatePrice, duration])

  const handleDurationChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    min?: number,
    max?: number
  ) => {
    console.log('value changed for an input', event, event.target.id)
    if (min && parseInt(event.target.value) < min) {
      return
    }
    if (max && parseInt(event.target.value) > max) {
      return
    }
    setDuration(event.target.value)
  }

  const handleVideoInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log('value changed for an input', event, event.target.id)
    // setVideoInputValue(event.target.value)
  }

  if (redirect) {
    history.push(`${ROUTES__CHANNEL}/${tags[0].text}`)
    setRedirect(false)
    return null
  }

  const sendRawTx = async (rawtx: string) => {
    try {
      let res = await fetch('https://localhost:3000/signtest', {
        method: 'POST',
        body: JSON.stringify({ rawtx })
      })
      console.log('notified backend', res)
    } catch (e) {
      throw e
    }
  }

  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault()

    console.log(videoInputValue, props)
    if (!videoInputValue || !videoInputValue.length) {
      console.log('no input value!', videoInputValue)
      return null
    }

    if (!tags.length) {
      alert('Please select a channel to post to.')
    }
    // reply or edit mode
    // let mode = props.mode || 'video'

    console.log('submit form', videoInputValue, 'props', props, relay)

    let data = [
      '1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5',
      'SET',
      'app',
      'minerva.live',
      'type',
      'video',
      'provider',
      'youtube',
      'videoID',
      videoInputValue,
      'duration',
      String(duration),
      'start',
      String(start),
      'context',
      'videoID',
      'subcontext',
      'provider'
    ]

    // check for handcash token
    if (authToken) {
      let hexArray = data.map((str) => bops.to(bops.from(str, 'utf8'), 'hex'))

      const resp = await fetch(`https://minerva.live/hcsend/`, {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        body: JSON.stringify({
          hexArray,
          authToken,
          description: 'video ' + videoInputValue // must be < 25 chars
        })
      })

      const r = await resp?.json()
      console.log({ result: r?.paymentResult })

      toast('🦄 Posted! ' + r?.paymentResult?.transactionId, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      setVideoInputValue('')

      setRedirect(true)
      return
      // https://bitchatnitro.com/hcsend/
      // { hexArray, authToken}
    }

    // ToDo - add other tags
    let s = Script.fromSafeDataArray(
      data.map((d) => {
        return Buffer.from(d)
      })
    )

    let data2 = [
      '1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5',
      'ADD',
      'tags',
      ...tags.map((t) => {
        return t.text
      })
    ]
    let s2 = Script.fromSafeDataArray(
      data2.map((d) => {
        return Buffer.from(d)
      })
    )
    console.log(usd)

    try {
      let res = await relay.send({
        outputs: [
          {
            amount: '0',
            currency: 'USD',
            script: s.toAsmString(),
            signatures: ['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA-BITCOM-HASHED']
          },
          {
            amount: '0',
            currency: 'USD',
            script: s2.toAsmString(),
            signatures: ['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA-BITCOM-HASHED']
          },
          {
            to: '1xoxoxoR29oXEnxf7Wb6czNjFuXZjveTa',
            amount: usd.substring(1),
            currency: 'USD'
          }
        ]
      })
      console.log(res)

      // now send the raw tx directly to planaria
      if (res.rawtx) {
        sendRawTx(res.rawtx)
      }

      toast('🦄 Posted! ' + res.txid, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })

      setVideoInputValue('')

      setRedirect(true)
    } catch (e) {
      console.log('e', e)
    }
  }

  const handleDelete = (index: number) => {
    let newTags = tags.slice(0)
    newTags.splice(index, 1)
    setTags(newTags)
  }

  const handleAddition = (tag: Tag) => {
    setTags([...tags, tag])
  }

  const handleTagClick = () => {
    console.log('erm tagClick')
  }

  const postDisabled = () => {
    return tags.length === 0
  }

  return (authenticated || authToken) && youTubeVideo ? (
    <ScPostForm onSubmit={submitForm}>
      <Card>
        <ScInput
          name="video"
          value={videoInputValue}
          onChange={handleVideoInputChange}
          disabled
          hidden
        />
        <ScTagsRow>
          <ScTags>
            <ScLabel>Channel: </ScLabel>
            {tags && tags.length === 0 ? (
              <ReactTags
                tags={tags}
                suggestions={channels}
                handleDelete={handleDelete}
                handleAddition={handleAddition}
                handleTagClick={handleTagClick}
                placeholder="music, trailers, gaming, bitcoin, etc..."
              />
            ) : (
              <ScSelectedTag onClick={clearTags}>{tags[0].text}</ScSelectedTag>
            )}
          </ScTags>
          {showAdvanced ? (
            <div></div>
          ) : (
            <ScToggleAdvanced onClick={toggleAdvanced}>
              Advanced <IoIosArrowDown />
            </ScToggleAdvanced>
          )}
        </ScTagsRow>
        {showAdvanced ? (
          <ScAdvanced>
            <ScFields>
              <ScLabel>Start (seconds)</ScLabel>
              <ScInput
                id="start"
                type="number"
                min={0}
                max={parseInt(props.duration) - 10}
                value={start}
                onChange={(e) => {
                  handleStartChange(e, 0, parseInt(props.duration) - 10)
                }}
              />
              <ScLabel>Duration (seconds)</ScLabel>
              <ScInput
                id="duration"
                type="number"
                value={duration}
                min="1"
                max={parseInt(props.duration) - parseInt(start)}
                onChange={(e) => {
                  handleDurationChange(
                    e,
                    1,
                    parseInt(props.duration) - parseInt(start)
                  )
                }}
              />
            </ScFields>
          </ScAdvanced>
        ) : null}

        <ScActions>
          <Button
            disabled={postDisabled()}
            background={'#7a89d6'}
            color={'white'}
            type="submit"
          >
            Post {usd}
          </Button>
        </ScActions>
      </Card>
    </ScPostForm>
  ) : null
}

export default PostForm

const ScAdvanced = styled.div`
  padding: 1rem;
`

const ScSelectedTag = styled.div`
  cursor: pointer;
  &:hover {
    color: #eee;
  }
`

const ScToggleAdvanced = styled.div`
  cursor: pointer;
  color: #7a89d6;
  &:hover {
    color: ${lighten('.1', '#7a89d6')};
  }
`
const ScTags = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 80px 1fr;
  height: 32px;
  color: #ccc;
`

const ScTagsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`

const ScPostForm = styled.form`
  display: flex;
  flex-direction: column;
`

const ScInput = styled.input`
  padding: 1rem;
  font-size: 1.25rem;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #7a89d6;
  outline: none;
  grid-column: 2;
  color: #aaa;
  &:focus {
    color: #eee;
    border-color: #09f911;
  }
`

const ScActions = styled.div`
  display: flex;
`

const ScLabel = styled.div`
  grid-column: 1;
`

const ScFields = styled.div`
  align-items: center;
  display: grid;
  grid-template-rows: 1fr 1fr;
`

import React, { useCallback } from 'react'
import { useRelay } from '../../context/relay/RelayProvider'
import { RouteComponentProps } from 'react-router-dom'
import { Card } from '../card'
import styled from 'styled-components'
import { Button } from '../button'
type Props = RouteComponentProps

const Help: React.FC<Props> = (props) => {
  const { relayotc, paymail } = useRelay()

  const openOtcPopup = useCallback(() => {
    relayotc.buy('widget-container', { to: paymail })
  }, [relayotc, paymail])

  return (
    <ScHelp>
      <Card>
        <h2>Help</h2>
        <div>
          Minerva is a real-time collaborative video system build on Bitcoin SV.
          All records are stored on-chain and playlist order can be
          independently validated. Bitcoin SV is required to make new posts or
          interact with others on this site. To get started, download the Relay
          X wallet on your phone and pair it with Minerva when prompted.
          <p>If you don't have any BSV, you can get some right here:</p>
          <Button onClick={openOtcPopup}>Buy BSV</Button>
          <div id="widget-container"></div>
          <div>
            <ScRelay>
              <ScRelayLogo src="/relay.png" alt="relay-x superwallet" />
            </ScRelay>
            <ScBadges>
              <a href="https://play.google.com/store/apps/details?id=com.RelayX">
                <ScBadge src="/apple.jpg" alt="app store" />
              </a>
              <a href="https://testflight.apple.com/join/XJCahkvx">
                <ScBadge src="/googleplay.png" alt="play store" />
              </a>
            </ScBadges>
          </div>
        </div>
      </Card>
    </ScHelp>
  )
}

export default Help

const ScRelay = styled.div`
  width: 100%;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
`

const ScRelayLogo = styled.img`
  width: 120px;
`

const ScBadge = styled.img`
  max-width: 155px;
`

const ScBadges = styled.div`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  width: 400px;
  margin: auto;
  text-align: center;
`

const ScHelp = styled.div`
  max-width: 1480px;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
`

import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import YouTube, { Options } from 'react-youtube'
// import { useBmap } from '../../context/bmap/BmapProvider'

type Props = RouteComponentProps<{ videoId: string }>

const Watch: React.FC<Props> = ({
  match: {
    params: { videoId }
  }
}) => {
  // const { appStates, appStatesPlayed } = useBmap()

  const opts: Options = {
    height: '790',
    width: '640',
    playerVars: {
      autoplay: 1,
      start: 0,
      controls: 1,
      rel: 0,
      fs: 0
    }
  }

  //if (appStates && appStatesPlayed) {
  // ToDo - we need a real relationship between video and tx. this could be commenting on wrong tx if this video is in the list many times in diff channels for ex
  // let vid = playedTxs
  //   .concat(txs)
  //   .filter((v) => {
  //     return v.MAP.videoID === videoId
  //   })
  //   .map((v) => {
  //     return v.MAP.videoID
  //   })[0]
  // console.log('video id', vid, videoId)
  return (
    <ScWatch>
      <YouTube className="player" opts={opts} videoId={videoId} />
    </ScWatch>
  )
  //}
  // return null
}

export default Watch

const ScWatch = styled.div``

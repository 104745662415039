import React, { useCallback } from 'react' //Always need in JSX files
import { history } from '../../../context/router/history'
import styled from 'styled-components'
import { GoSearch } from 'react-icons/go'
import { Button } from '../../button'
import { useYouTube } from '../../../context/youtube/YouTubeProvider'

const SearchBar: React.FC<{
  redirectRoute: string
}> = (props) => {
  const { setQuery } = useYouTube()
  // const [triggerSearch, setTriggerSearch] = useState<boolean>(false)

  const onInputChange = (term: string) => {
    setQuery(term)
  }

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      let route = props.redirectRoute
      setQuery('')
      history.push(route)
    },
    [props, setQuery]
  )

  return (
    <ScSearchBar
      onSubmit={(e) => {
        e.preventDefault()
        onSubmit(e)
      }}
    >
      <ScSearchBarInput
        id="search-input"
        onChange={(event) => onInputChange(event.target.value)}
      />
      <Button
        color="rgba(255,255,255,0.5)"
        background="rgb(48,48,48)"
        type="submit"
        width="65px"
        borderRadius="0 2px 2px 0"
      >
        <GoSearch />
      </Button>
    </ScSearchBar>
  )
}

export default SearchBar

const ScSearchBar = styled.form`
  width: 100%;
  max-width: 768px;
  justify-content: center;
  display: flex;
  text-align: center;
  display: flex;
  margin: auto;
  min-width: 260px;
`

const ScSearchBarInput = styled.input`
  width: 100%;
  color: rgba(255, 255, 255, 0.88);
  font-size: 1rem;
  padding: 0.5rem;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.08);
  border: 1px solid #303030;
  border-radius: 2px 0 0 2px;
  outline: none;
  &:focus {
    box-shadow: 0 0 2pt 1pt #7a89d6;
  }
`

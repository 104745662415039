import { State } from './BmapProvider'

interface Props {}

const getTx = (txid: string, states: State[]) => {
  return states?.map((s) => {
    return s.playlist.filter((t) => {
      return t.txid === txid
    })[0]
  })[0]
}

export { getTx }

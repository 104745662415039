import { Script } from 'bsv'
import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBmap } from '../../context/bmap/BmapProvider'
import { useRelay } from '../../context/relay/RelayProvider'
import BitPic from '../bitpic'
import { Button } from '../button'
import { Card } from '../card'
import VideoList from '../find-video/video-list'

type Props = RouteComponentProps<{ paymail: string }>

const Profile: React.FC<Props> = ({
  match: {
    params: { paymail }
  }
}) => {
  // const { getFollowing, following } = useUser()
  const {
    appStates,
    appStatesPlayed,
    fetchFollowedVideos,
    followedVideos,
    fetchLikedVideos,
    likedVideos,
    isFetching,
    likedTxs
  } = useBmap()
  const { relay, paymail: myPaymail } = useRelay()
  const [profilePaymail, setProfilePaymail] = useState<string | null>(null)
  const [isFollowing, setIsFollowing] = useState<boolean>(false)

  useEffect(() => {
    async function fetchData() {
      console.log('supposed to be getting for', paymail)
      await fetchFollowedVideos(paymail)
    }
    if (!followedVideos && !isFetching) {
      fetchData()
    }
  }, [fetchFollowedVideos, paymail, followedVideos, isFetching])

  useEffect(() => {
    async function fetchData() {
      console.log('supposed to be getting for', paymail)
      if (appStates && appStatesPlayed) {
        await fetchLikedVideos(paymail, appStates.concat(appStatesPlayed))
      }
    }
    if (
      (appStates && appStatesPlayed && !profilePaymail) ||
      (paymail && profilePaymail && paymail !== profilePaymail)
    ) {
      fetchData()
      setProfilePaymail(paymail)
    }
  }, [
    fetchLikedVideos,
    paymail,
    likedVideos,
    profilePaymail,
    appStatesPlayed,
    appStates
  ])

  const editBitPic = useCallback(() => {
    let url = `https://bitpic.network/${
      myPaymail === profilePaymail
        ? 'upload#' + myPaymail
        : 'me/' + profilePaymail
    }`
    var win = window.open(url, '_blank')

    win?.focus()
  }, [myPaymail, profilePaymail])

  const follow = async () => {
    let data = [
      '1PuQa7K62MiKCtssSLKy1kh56WWU7MtUR5',
      'SET',
      'app',
      'minerva',
      'type',
      'follow',
      'context',
      'paymail',
      'paymail',
      paymail
    ]

    // ToDo - add other tags
    let s = Script.fromSafeDataArray(
      data.map((d) => {
        return Buffer.from(d)
      })
    )

    try {
      let res = await relay.send({
        outputs: [
          {
            amount: '0',
            currency: 'USD',
            script: s.toAsmString(),
            signatures: ['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA-BITCOM-HASHED']
          },
          {
            to: paymail,
            currency: 'USD',
            amount: '0.10'
          }
        ]
      })

      setIsFollowing(true)

      toast('🦄 Followed! ' + res.txid + ' - $0.10', {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      })
    } catch (e) {
      console.log('e', e)
    }
  }
  console.log('my paymail is', myPaymail, 'vs', paymail)
  return (
    <ScProfile>
      <ScTitle>Profile</ScTitle>
      <br />
      <ScProfilePic onClick={editBitPic}>
        <BitPic paymail={paymail}></BitPic>
      </ScProfilePic>

      <Card>
        <ScField>
          <label>Paymail:</label>
          {paymail}
        </ScField>
        {isFollowing ? <ScFollowing>Following</ScFollowing> : null}
      </Card>
      <br />
      <ScActions>
        {isFollowing || !myPaymail || paymail === myPaymail ? null : (
          <Button
            onClick={() => {
              follow()
            }}
          >
            Follow $0.10
          </Button>
        )}
      </ScActions>

      <br />

      {myPaymail && likedTxs ? (
        <Card>
          {likedVideos && (
            <VideoList
              videos={likedVideos}
              onVideoSelect={() => {
                console.log('video selected')
              }}
            ></VideoList>
          )}
        </Card>
      ) : null}
    </ScProfile>
  )
}

export default Profile

const ScField = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 100px 1fr;
  padding-bottom: 0.25rem;
`
const ScActions = styled.div`
  margin: auto;
`

const ScTitle = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  color: #575757;
  width: 100%;
  margin: 0.5rem 0;
`

const ScProfile = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 720px;
  margin: auto;
`

const ScFollowing = styled.div`
  padding: 0.25rem;
  font-size: 0.8rem;
  color: #ccc;
  background: #111;
  border-radius: 0.25rem;
`

const ScProfilePic = styled.div`
  width: 200px;
  margin: 1rem auto;
  cursor: pointer;
`

import React from 'react'
import VideoListItem from '../video-list-item'
import { Video, YouTubeVideo } from 'minerva'
import styled from 'styled-components'

const VideoList: React.FC<{
  videos?: Video[]
  youTubeVideos?: YouTubeVideo[]
  onVideoSelect: Function
}> = (props) => {
  const videos = props.videos?.length ? props.videos : props.youTubeVideos
  const videoItems = videos?.length ? (
    <ScVideoList>
      {props.videos?.map((video: Video, idx: number) => {
        return (
          <VideoListItem
            video={video}
            onVideoSelect={props.onVideoSelect}
            key={video.txid}
          />
        )
      })}

      {props.youTubeVideos?.map((youTubeVideo: YouTubeVideo, idx: number) => {
        console.log('youtube videos present!', youTubeVideo)

        return (
          <VideoListItem
            onVideoSelect={props.onVideoSelect}
            key={youTubeVideo.id}
            youtubeData={youTubeVideo}
          />
        )
      })}
    </ScVideoList>
  ) : null

  return <ScVideoList>{videoItems}</ScVideoList>
}

export default VideoList

const ScVideoList = styled.ul`
  background-color: #fff;
  margin: auto;
  max-width: 1480px;
  border-radius: 0.5rem;
  padding: 2rem;
`

import { BmapTx } from 'bmapjs'

import { Video, YouTubeVideo } from 'minerva'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import merge from '../../utils/bmap'
import { followedVideosQuery, likedVideosQuery } from './queries'
import { getTx } from './tx'

// type State = {
//   channel: string
//   tx: BmapTx[]
//   video: Video[]
//   votes?: Vote[]
//   likes?: Like[]
//   player: {
//     position: number
//     txid: string
//   }
// }

type ContextValue = {
  channel: (channel: string) => State
  channelPlayed: (channel: string) => State
  initialized: Boolean
  isFetching: Boolean
  error: Error | null
  getTx: (tx: string, states: State[]) => PlaylistItem
  appStates: State[] | null
  appStatesPlayed: State[] | null
  pop: (channel: string) => void
  handleSocket: (e: MessageEvent) => void
  likedTxs: BmapTx[] | null
  likedVideos: Video[] | null
  fetchLikedVideos: (myPaymail: string, states: State[]) => Promise<void>
  fetchLikesByTxId: (txid: string) => Promise<string | null>
  followedVideos: BmapTx[] | null
  fetchFollowedVideos: (myPaymail: string) => Promise<void>
  getVideoByTxID: (txid: string) => Promise<VideoData | null>
}

type Player = {
  position: number
  videoID?: string
  provider?: string
  txid?: string
}

type PlaylistItem = {
  txid: string
  videoID: string
  upvotePOW: number
  downvotePOW: number
  provider: string
  duration: number
  start: number
  timestamp?: number
  totalLikes?: number
  tags?: string[]
}

type State = {
  channel: string
  player: Player
  playlist: PlaylistItem[]
}

type VideoData = {
  videoTx: BmapTx
  providerData: YouTubeVideo
}

interface Props {}

const BmapContext = React.createContext<ContextValue | undefined>(undefined)

const BmapProvider: React.FC<Props> = (props) => {
  const [error, setError] = useState<Error | null>(null)
  const [isFetching, setIsFetching] = useState<Boolean>(false)
  const [initialized, setInitialized] = useState<Boolean>(false)
  const [appStates, setAppStates] = useState<State[] | null>(null)
  const [appStatesPlayed, setAppStatesPlayed] = useState<State[] | null>(null)

  // Follow

  const [followedVideos, setFollowedVideos] = useState<BmapTx[] | null>(null)

  const fetchFollowedVideos = useCallback(async (myPaymail: string) => {
    const b64 = Buffer.from(
      JSON.stringify(followedVideosQuery(myPaymail))
    ).toString('base64')
    try {
      let res = await fetch('https://b.map.sv/q/' + b64)
      let j = await res.json()
      console.log('gotem!', j)

      setFollowedVideos((j.u || []).concat(j.c || []))
    } catch (e) {
      console.error(e)
    }
  }, [])

  // Like
  const [likedVideos, setLikedVideos] = useState<Video[] | null>(null)
  const [likedTxs, setLikedTxs] = useState<BmapTx[] | null>(null)

  // PLANARIA SIDE - SET LIKED TOTAL just like POW

  // Will fetch and set liked txs and their corresponding liked videos
  const fetchLikedVideos = useCallback(
    async (myPaymail: string, states: State[]) => {
      const b64 = Buffer.from(
        JSON.stringify(likedVideosQuery(myPaymail))
      ).toString('base64')
      try {
        let res = await fetch('https://b.map.sv/q/' + b64)
        let j = await res.json()
        let l = (j.u || []).concat(j.c || [])

        setLikedTxs(l)

        let lV: Video[] = []
        for (let t of l) {
          let v = states
            .filter((s: State) => {
              return s.playlist.some((item) => {
                return item.txid === t.tx.h
              })
            })[0]
            .playlist.filter((item) => {
              return item.txid === t.tx.h
            })[0]
          lV.push(v)
        }
        setLikedVideos(lV)
      } catch (e) {
        console.error(e)
      }
    },
    []
  )

  // const statesFromTxRef = useCallback((t: BmapTx, appStates: State[]) => {
  //   // Todo remove this
  //   if (!appStates || !appStates.length) {
  //     return { state: null, otherStates: null }
  //   }
  //   console.log('getting state for', t.tx.h, t.MAP.tx, appStates.length)
  //   let state = appStates?.filter((s: State) => {
  //     return s.playlist.some((x) => {
  //       return x.txid === t.MAP.tx
  //     })
  //   })[0]
  //   let otherStates = appStates?.filter((s) => {
  //     return !s.playlist.some((x) => {
  //       return x.txid === t.MAP.tx
  //     })
  //   })
  //   return { state: state, otherStates: otherStates }
  // }, [])

  const channel = useCallback(
    (channelName: string) => {
      let emptyState = {
        channel: channelName,
        player: { position: 0, txid: '' },
        playlist: []
      } as State

      if (!appStates || !appStates.length) {
        return emptyState
      }

      let states = appStates.filter((s: State) => {
        return s.channel === channelName
      })

      return states && states.length ? states[0] : emptyState
    },
    [appStates]
  )

  const channelPlayed = useCallback(
    (channelName: string) => {
      let emptyState = {
        channel: channelName,
        player: { position: 0, txid: '' },
        playlist: []
      } as State

      if (!appStatesPlayed || !appStatesPlayed.length) {
        return emptyState
      }

      let states = appStatesPlayed.filter((s: State) => {
        return s.channel === channelName
      })

      return states && states.length ? states[0] : emptyState
    },
    [appStatesPlayed]
  )

  // const sortPlaylistByPOW = useCallback((playlist: PlaylistItem[]) => {
  //   return playlist.sort((a, b) => {
  //     if (a.upvotePOW - a.downvotePOW === b.upvotePOW - b.downvotePOW) {
  //       return 0
  //     }
  //     return a.upvotePOW - a.downvotePOW > b.upvotePOW - b.downvotePOW ? -1 : 1
  //   })
  // }, [])

  // remove the playing video from a channel and put it in history
  const pop = useCallback(
    (chan: string) => {
      if (!appStates || !appStatesPlayed) {
        console.log('cantPop', appStates, appStatesPlayed)
        return
      }

      let newState = channel(chan)
      console.log('lets pop', newState.playlist)
      if (newState.playlist && newState.playlist.length) {
        console.log('popping from', newState.playlist)
        let playedVideo = newState.playlist.shift() || null
        console.log('removed?', newState.playlist)
        newState.player.position = 0

        let newAppStates = appStates?.map((s: State, i: number) => {
          if (s.channel === chan) {
            return newState
          }
          return s
        })
        setAppStates(newAppStates)

        if (playedVideo) {
          let newStatePlayed = channelPlayed(chan)
          newStatePlayed.playlist.push(playedVideo)
          let newAppStatesPlayed = appStatesPlayed?.map(
            (s: State, i: number) => {
              if (s.channel === chan) {
                return newStatePlayed
              }
              return s
            }
          )
          setAppStatesPlayed(newAppStatesPlayed)
        }
        console.log('popped', playedVideo)
      }
    },
    [appStates, appStatesPlayed, channel, channelPlayed]
  )

  const init = useCallback(
    (channels?: string[]) => {
      async function fetchData() {
        try {
          setIsFetching(true)
          let resp = await fetch(
            'https://api.minerva.live/state' +
              (channels && channels?.length
                ? '/channels/' + channels.join(',')
                : '')
          )
          let j = await resp.json()

          let as = j.map((s: State) => {
            let b = []
            for (let item of s.playlist) {
              item.timestamp = new Date().getTime()
              b.push(item)
            }
            s.playlist = b
            let timeout = s.playlist.length
              ? (s.playlist[0].duration - s.player.position) * 1000
              : null
            if (timeout) {
              setTimeout(() => {
                console.log('popping', s.channel)
                pop(s.channel)
              }, timeout)
            }

            return s
          })

          setAppStates(as)
          if (!channels) {
            // played
            let resp2 = await fetch('https://api.minerva.live/history')
            let j2 = await resp2.json()
            let asp = j2.map((s: State) => {
              let b = []

              for (let item of s.playlist) {
                // Merge Multiple OP_RETURNS

                item.timestamp = new Date().getTime()
                b.push(item)
              }
              s.playlist = b
              return s
            })
            setAppStatesPlayed(asp)
          }

          setIsFetching(false)
        } catch (e) {
          console.error('failed', e)
          setError(e)
          setIsFetching(false)
        }
      }
      fetchData()
    },
    [pop]
  )

  // use effect to look at every tx timestamp, compare to gurrent time and duration.
  // pop off expired videos of channels we are not currently viewing
  // (or just remove the pop from channel and let this do everything)
  useEffect(() => {
    appStates?.filter((state) => {
      return state.playlist.filter((t) => {
        console.log(
          'timestamp',
          t.timestamp,
          'duration',
          t.duration,
          'now',
          new Date().getTime()
        )
      })
    })
  }, [appStates])

  const handleSocket = useCallback(
    (e: MessageEvent) => {
      let d = JSON.parse(e.data)
      switch (d.type) {
        case 'open':
          console.log('bitsocket opened')
          break
        case 'push':
          // save latest_block
          d.data.forEach(async (item: BmapTx) => {
            try {
              // TODO!
              // await saveTx(item)
              let t = item
              // let t = await bmap.TransformTx(item)
              // Multiple OP_RETURNS makes MAP an array. merge them
              t.MAP = Array.isArray(t.MAP) ? merge(...t.MAP) : t.MAP
              switch (t.MAP.type) {
                case 'like':
                  console.log('TODO - add like')
                  // setAppStates((prevAppStates: State[] | null) => {
                  //   if (!prevAppStates) {
                  //     return prevAppStates
                  //   }
                  //   const { state, otherStates } = statesFromTxRef(
                  //     t,
                  //     prevAppStates
                  //   )
                  //   if (state) {
                  //     if (!state.likes) {
                  //       state.likes = []
                  //     }

                  //     state.likes.push({
                  //       tx: t.MAP.tx || '',
                  //       paymail: t['15igChEkUWgx4dsEcSuPitcLNZmNDfUvgA'][4].s
                  //     })

                  //     return (otherStates || []).concat([state])
                  //   }
                  //   return prevAppStates
                  // })

                  break
                case 'video':
                  console.log('new video tx found, calling init!', t)
                  init(t.MAP.tags)
                  break
                case 'upvote':
                case 'downvote':
                  // TODO why ttf is appStates empty?!?!?!?
                  console.log('todo - handle vote socket')

                  // Find the channel to update
                  let channels = appStates
                    ?.filter((s) => {
                      return s.playlist.some((p) => {
                        return p.txid === t.MAP.tx
                      })
                    })
                    .map((s) => {
                      return s.channel
                    })

                  // init that channel
                  //todo just sort them
                  init(channels)

                // setAppStates((prevAppStates: State[] | null) => {
                //   console.log('and do we have prev?', prevAppStates)

                //   if (!prevAppStates) {
                //     return prevAppStates
                //   }
                //   const { state: voteState, otherStates } = statesFromTxRef(
                //     t,
                //     prevAppStates
                //   )
                //   console.log('state to update', voteState)
                //   if (voteState) {
                //     // ToDo
                //     txid: v.txid,
                //       // videoID: v.videoID,
                //       // upvotePOW: voteTotals.upvotes,
                //       // downvotePOW: voteTotals.downvotes,
                //       // provider: v.provider,
                //       // duration: v.duration,
                //       // start: v.start,
                //       // likes: likeTotal,

                //       voteState.playlist.push({
                //         txid: t.MAP.tx || '',
                //         value: t.MAP.type === 'upvote' ? 700 : -700
                //       })
                //     let sortedPlaylist = sortPlaylistByPOW(voteState.playlist)
                //     voteState.playlist = sortedPlaylist
                //     console.log('sorted', voteState)

                //     return (otherStates || []).concat([voteState])
                //   }
                //   return prevAppStates
                // })
              }
            } catch (e) {
              // record already exists in the db
            }
          })
          break
      }
    },
    [init, appStates]
  )

  useEffect(() => {
    if (!initialized) {
      setInitialized(true)
      init()
    }
  }, [initialized, init, appStates])

  const value = useMemo(
    () => ({
      error,
      initialized,
      isFetching,
      setAppStatesPlayed,
      appStates,
      setAppStates,
      appStatesPlayed,
      channel,
      channelPlayed,
      pop,
      handleSocket,
      likedVideos,
      likedTxs,
      fetchLikedVideos,
      fetchLikesByTxId: async (txid: string) => {
        try {
          let resp = await fetch('https://api.minerva.live/like/' + txid)
          let likes = await resp.json()

          // ToDo - Use the data - paymail
          return likes ? likes.length : null
        } catch (e) {
          return null
        }
      },
      followedVideos,
      fetchFollowedVideos,
      getTx,
      getVideoByTxID: async (txid: string) => {
        try {
          let resp = await fetch('https://api.minerva.live/video/' + txid)
          let { videoTx, providerData } = await resp.json()

          return videoTx && providerData
            ? ({ videoTx, providerData } as VideoData)
            : null
        } catch (e) {
          return null
        }
      }
    }),
    [
      error,
      initialized,
      isFetching,
      appStates,
      setAppStates,
      appStatesPlayed,
      setAppStatesPlayed,
      channel,
      channelPlayed,
      pop,
      handleSocket,
      likedVideos,
      likedTxs,
      fetchLikedVideos,
      followedVideos,
      fetchFollowedVideos
    ]
  )

  return <BmapContext.Provider value={value} {...props} />
}

const useBmap = (): ContextValue => {
  const context = useContext(BmapContext)
  if (context === undefined) {
    throw new Error('useBmap must be used within an BmapProvider')
  }

  return context
}
export type { State }
export { BmapProvider, useBmap }

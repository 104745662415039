import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Block } from '../block'

const MetaLens: React.FC<{
  title: string
  context: string
  value: string
  subcontext?: string
  subcontextValue?: string
  height?: number
}> = (props) => {
  // ToDo - loading is good but bad, disabled for now
  const [commentsLoading, setCommentsLoading] = useState<boolean>(false)

  const [initialized, setInitialized] = useState<boolean>(false)
  const [commentsHeight, setCommentsHeight] = useState<number | undefined>(
    props.height
  )
  const receiveMessage = useCallback((e: MessageEvent) => {
    if (
      e.origin !== 'https://www.youtube.com' &&
      e.data?.source !== 'react-devtools-content-script' &&
      e.data?.source !== 'react-devtools-detector' &&
      e.data?.source !== 'react-devtools-bridge'
    ) {
      // ToDo - Verify source is metalens
      switch (e.data.type) {
        case 'window':
          setCommentsHeight(e.data.height)
          break
        case 'success':
          toast('🦄 Posted! ' + e.data.txid, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
          break
      }
    }
  }, [])

  useEffect(() => {
    if (!initialized) {
      window.addEventListener('message', receiveMessage, false)
      setInitialized(true)
    }
  }, [receiveMessage, initialized])

  const hideLoading = useCallback(() => {
    setCommentsLoading(false)
  }, [])

  let params: any = {
    background: 'transparent',
    buttonBackground: '#7a89d6',
    buttonColor: 'white',
    color: 'white',
    inputBackground: 'transparent',
    inputColor: 'white',
    inputBorder: 'none'
  }

  let url = new URL(
    `${baseUrl}/embed/${props.subcontext ? props.subcontext + '/' : ''}${
      props.subcontextValue ? props.subcontextValue + '/' : ''
    }${props.context}/${props.value}`
  )
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  )

  return (
    <div>
      {commentsLoading ? <Block color="black" /> : null}
      <ScMetaLens
        title={props.title}
        height={commentsHeight}
        src={url.toString()}
        onLoad={hideLoading}
        scrolling="no"
      />
    </div>
  )
}

export default MetaLens

const ScMetaLens = styled.iframe<{ height?: number }>`
  width: 100%;
  border: 0;
  min-height: ${(props) => (props.height ? `${props.height}px` : 'unset')};
`

const baseUrl =
  process.env.ENVIRONMENT === 'production'
    ? `https://metalens-react.web.app`
    : `https://localhost:8080`
